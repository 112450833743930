import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux-store/stores/store';
import './Loader.css'; // Optional: for styling

const Loader = () => {
    const isLoading = useSelector((state: RootState) => state.loading);

    return (
        isLoading ? (
            <div className="loader">
                <div className="spinner"></div>
            </div>
        ) : <div></div>
    );
};

export default Loader;