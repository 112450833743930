import { initializeApp } from "firebase/app";
import { browserLocalPersistence, getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    "apiKey": "AIzaSyDn_iuG23PTc5rVgcP3bNsznpcsdY503sE",
    "authDomain": "aizenit.firebaseapp.com",
    "projectId": "aizenit",
    "storageBucket": "aizenit.appspot.com",
    "messagingSenderId": "595690527589",
    "appId": "1:595690527589:web:b4d1d589230145d8351f8c",
    "measurementId": "G-RTXXNW1PQD",
    "vapidKey": "BJJgxKnbFSxCmPETPVlMyWs871fiZjnzR54VTu4MNto4ZjepT1ppVlhIj4FbHXjNsngNsM8xNYCN-TRUfLSL-f8"
}
const app = initializeApp(firebaseConfig)
export const auth =  getAuth(app)
auth.setPersistence(browserLocalPersistence).then(()=>{   
})
export const db = getFirestore(app)
