import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from ".";



export const getTeams = createAsyncThunk(
    'api/getTeams',
    async (_, { rejectWithValue }) => {
      const api = axiosInstance
      try {
        const { data } = await api.get('Users/groups');
        return data;
      } catch (error: any) {
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message);
        } else {
          return rejectWithValue(error.message);
        }
      }
    }
  )


export const createTeam = createAsyncThunk(
    'api/createTeam',
    async (teamDetails:any, { rejectWithValue }) => {
      const api = axiosInstance
      try {
        const { data } = await api.post('Users/group',teamDetails);
        return data;
      } catch (error: any) {
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message);
        } else {
          return rejectWithValue(error.message);
        }
      }
    }
  )

  export const deleteTeam = createAsyncThunk(
    'api/groupDelete',
    async (team: any, { rejectWithValue }) => {
      console.log(team);
      const api = axiosInstance
      try {
        const { data } = await api.delete(`Users/group/${team.id}`)
        return data
      } catch (error: any) {
        // return custom error message from API if any
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message)
        } else {
          return rejectWithValue(error.message)
        }
      }
    }
  )

  export const updateTeam = createAsyncThunk(
    'api/teamUpdate',
    async (teamId: any, { rejectWithValue }) => {
      const api = axiosInstance
      try {
        const { data } = await api.post(`users/group/users`,teamId)
        return data
      } catch (error: any) {
        // return custom error message from API if any
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message)
        } else {
          return rejectWithValue(error.message)
        }
      }
    }
  )