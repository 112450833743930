import { useDispatch } from "react-redux"
import { AppDispatch } from "../../redux-store/stores/store"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../core/components/ui/card"
import { NavLink } from "react-router-dom"
import { resetSucess } from "../../redux-store/reducers/auth-reducers"


export default function ForgetpasswordInfo() {
    const dispatch: AppDispatch = useDispatch()

    const handleSignIn = () => {
        dispatch(resetSucess())
    }

    return (
        <div className="flex flex-col min-h-screen w-full items-center justify-center">
            <div className="flex h-full items-center justify-center">
                <Card className="mx-auto max-w-sm">
                    <CardHeader>
                        <CardTitle className="text-xl">Forgot password?</CardTitle>
                        <CardDescription>
                            Enter the email you used to signup with stashify and we'll send you a link to reset your password.
                        </CardDescription>
                        <CardDescription className="border border-green-500 p-2 rounded-lg bg-green-100">
                            We have received your request. If a matching account is found, an email will be sent to your email with password reset instructions.
                        </CardDescription>
                    </CardHeader>
                    <CardContent>
                        <div>
                            <div className="mt-2 text-start text-sm">
                                Return to{" "}
                                <NavLink to="/login" className="underline" onClick={handleSignIn}>
                                    Sign in
                                </NavLink>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </div>
        </div>
    )
}