import { createAsyncThunk } from '@reduxjs/toolkit';
import { getHeaders } from '.';
import { GetToken } from './manager';
import { signInWithCustomToken } from "firebase/auth";
import { auth } from './firebase';
import axios from 'axios';
import { getConfig } from './config-handler';

const _config = getConfig()

const api = axios.create({
  baseURL: `${_config?.REACT_APP_API_URL}/api`
})

export const loginAction = createAsyncThunk(
  'auth/login',
  async (credentials: any, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON

      const { data } = await api.post(
        '/auth/login',
        credentials,
        getHeaders(null)
      )
      // store user's token in local storage
      localStorage.setItem('token', data.accessToken)
      localStorage.setItem('refresh-token', data.refreshToken)
      localStorage.setItem('company-list', JSON.stringify(data.companiesTokenList))
      GetToken();
      await signInWithCustomToken(auth, data.firebaseToken);
      return data
    } catch (error: any) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const signup = createAsyncThunk(
  'auth/signup',
  async (credentials: any, { rejectWithValue }) => {
    try {

      const { data } = await api.post(
        '/auth/new-account',
        credentials,
        getHeaders(null)
      )
      return data
    } catch (error: any) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)


export const verifyEmail = createAsyncThunk(
  'auth/verify',
  async (credentials: any, { rejectWithValue }) => {
    try {
      const { data } = await api.put(
        '/auth/email-verification',
        credentials
      )
      return data
    } catch (error: any) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const resetPassowrd = createAsyncThunk(
  'auth/resetPassword',
  async (credentials: any, { rejectWithValue }) => {
    try {
      const { data } = await api.put(
        '/auth/reset-password',
        credentials
      )
      return data
    } catch (error: any) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (credentials: any, { rejectWithValue }) => {
    try {
      const { data } = await api.post(
        '/auth/forgot-password',
        credentials
      )
      return data
    } catch (error: any) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)


