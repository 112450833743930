import { Card, CardHeader, CardTitle, CardContent } from "../../core/components/ui/card"

import { NavLink, useParams } from "react-router-dom"
import { verifyEmail } from "../../shared/services/api"
import { useSelector, useDispatch } from "react-redux"
import { RootState, AppDispatch } from "../../redux-store/stores/store"
import { resetSucess } from "../../redux-store/reducers/auth-reducers"
import { useEffect, useRef } from "react"

export default function ConfirmEmail() {
    const { loading, success } = useSelector((state: RootState) => state.auth)
    const dispatch: AppDispatch = useDispatch()

    const searchParams = useParams<{ token: string }>();
    const hasLoaded = useRef(false);

    useEffect(() => {
        if (searchParams.token && !hasLoaded.current) {
            dispatch(verifyEmail({ token: searchParams.token }))
            hasLoaded.current = true;
        } // eslint-disable-next-line
    }, [searchParams.token])

    const handleSignIn = () => {
        dispatch(resetSucess())
    }

    return (
        <div className="flex flex-col min-h-screen w-full items-center justify-center">
            <div className="flex h-full items-center justify-center">
                <Card className="mx-auto max-w-sm">
                    <CardHeader>
                        <CardTitle className="text-xl">Email Confirmation</CardTitle>
                    </CardHeader>
                    <CardContent>
                        {loading && !success ?
                            <div>
                                <span className="text-primary">Email Confirmation is in progress...</span>
                            </div> :
                            <div>
                                {success ?
                                    <div className="mt-4 text-start text-sm">
                                        <p>Successfully Verified. Continue with</p>{" "}
                                        <NavLink to="/" className="underline" onClick={handleSignIn}>
                                            Sign in
                                        </NavLink>
                                    </div> :
                                    <div className="mt-4 text-start text-sm">
                                        <p>Email verification failed! Invalid or expired URL</p>
                                    </div>
                                }

                            </div>
                        }
                    </CardContent>
                </Card>
            </div>
        </div>
    )
}
