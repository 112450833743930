import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { CreateWorkspacce } from "../../pages/workspace/create-workspace";
import { GetCompanyListLength } from "../../shared/services/manager";
import LogedLayout from "../layout/protected/logged-layout";

export const ProtectedRoute = () => {
  const isAuthenticated = useSelector((state: any) => state.auth.isAuthenticated)
  const companies = GetCompanyListLength()
  return isAuthenticated ? companies === 0 ?<CreateWorkspacce /> : <LogedLayout /> : <Navigate to='/login' replace />
}