import { FC, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "../../../core/components/ui/dialog"
import { ScrollArea } from "../../../core/components/ui/scroll-area";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../core/components/ui/tabs";
import { AppDispatch, RootState } from "../../../redux-store/stores/store";
import { ChatService } from "../../services/chat/chat-service";
import { getConfig } from "../../services/config-handler";
import { getFolderSyncStatus } from "../../services/userSetting";
import { SyncProgess } from "./syncing-progress";

interface FolderSyncDialogProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    data: any
}

export const FolderSyncStatusDialog: FC<FolderSyncDialogProps> = ({ open, setOpen, data }) => {
    const { syncingList } = useSelector((state: RootState) => state.userSetting)
    const { userInfo } = useSelector((state: RootState) => state.auth)
    const dispatch = useDispatch<AppDispatch>()
    const isLoadFolders = useRef(false)

    const chatServiceInstatnce = useMemo(() => new ChatService(), []);

    useEffect(() => {
        if (!isLoadFolders.current && open) {
            dispatch(getFolderSyncStatus(data.id))
            isLoadFolders.current = true;
        }
        return () => { isLoadFolders.current = false }// eslint-disable-next-line 
    }, [open])

    useEffect(() => {
        if (syncingList && syncingList.filter(item => item.status === 0).length > 0) {
            const config = getConfig();
            const processesToSync = syncingList.filter(item => item.status === 0);

            const unsubscribeList = processesToSync.map(process => {
                const unsubscribe = chatServiceInstatnce.getSyncStatus(
                    parseInt(userInfo?.userId as string),
                    process.id,
                    "SyncWindow",
                    (resp: any) => {
                        if (resp.length > 0) {
                            const message = resp[0];
                            dispatch(getFolderSyncStatus(data.id))
                            switch (message?.ProgressBarStatus) {
                                case 1:
                                    break;
                                case 2:
                                    const path = `${config?.REACT_APP_ENV}/User/${userInfo?.userId}/Notifications/${message.id}`;
                                    message.IsViewed = true;
                                    chatServiceInstatnce.updateViewedMessage(path, message);
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
                );

                return unsubscribe;
            });

            return () => unsubscribeList.forEach(unsubscribe => unsubscribe());
        }// eslint-disable-next-line 
    }, [syncingList]);



    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogContent className="md:max-w-[1000px]">
                <DialogHeader>
                    <DialogTitle className="font-bold text-2xl">Sync status of {data.name}</DialogTitle>
                    <DialogDescription>All synced progress</DialogDescription>
                </DialogHeader>
                <Tabs defaultValue="active" className="w-full">
                    <TabsList className="grid w-full grid-cols-3 z-10">
                        <TabsTrigger value={"active"} >Active</TabsTrigger>
                        <TabsTrigger value={"completed"} >Completed</TabsTrigger>
                        <TabsTrigger value={"stoped"} >Stoped</TabsTrigger>
                    </TabsList>
                    <TabsContent className="p-4 min-h-[30rem] max-h-[30rem] overflow-auto" value={"active"}>
                        <ScrollArea>
                            {syncingList && syncingList.filter(item => item.status === 0).length > 0 &&
                                <div className="flex flex-col w-full  space-y-2">
                                    {syncingList.filter(items => items.status === 0).map((item, index) => (
                                        <div key={index} className="flex flex-col w-full bg-muted p-2 rounded-xl">
                                            <SyncProgess {...item} />
                                        </div>
                                    ))}
                                </div>
                            }
                        </ScrollArea>
                        {syncingList && syncingList.filter(item => item.status === 0).length <= 0 &&
                            <div className="flex flex-col w-full  space-y-2 items-center justify-center">
                                <p>No sync process</p>
                            </div>
                        }

                        {!syncingList &&
                            <div className="flex flex-col w-full  space-y-2 items-center justify-center">
                                <p>No sync process</p>
                            </div>
                        }
                    </TabsContent>
                    <TabsContent className="p-4  min-h-[30rem] max-h-[30rem] overflow-auto" value={"completed"}>
                        <ScrollArea className="flex flex-col w-full space-y-2 overflow-auto">
                            {syncingList && syncingList.filter(item => item.status === 1).length > 0 &&
                                <div className="flex flex-col w-full  space-y-2">
                                    {syncingList.filter(items => items.status === 1).map((item, index) => (
                                        <div key={index} className="flex flex-col w-full bg-muted p-2 rounded-xl">
                                            <SyncProgess {...item} />
                                        </div>
                                    ))}
                                </div>
                            }
                        </ScrollArea>
                        {syncingList && syncingList.filter(item => item.status === 1).length <= 0 &&
                            <div className="flex flex-col w-full  space-y-2 items-center justify-center">
                                <p>No sync process</p>
                            </div>
                        }

                        {!syncingList &&
                            <div className="flex flex-col w-full  space-y-2 items-center justify-center">
                                <p>No sync process</p>
                            </div>
                        }
                    </TabsContent>
                    <TabsContent className="p-4  min-h-[30rem] max-h-[30rem] overflow-auto" value={"stoped"}>
                        <ScrollArea className="flex flex-col w-full space-y-2 overflow-auto">
                            {syncingList && syncingList.filter(item => item.status === 2).length > 0 &&
                                <div className="flex flex-col w-full  space-y-2">
                                    {syncingList.filter(items => items.status === 2).map((item, index) => (
                                        <div key={index} className="flex flex-col w-full bg-muted p-2 rounded-xl">
                                            <SyncProgess {...item} />
                                        </div>
                                    ))}
                                </div>
                            }
                        </ScrollArea>
                        {syncingList && syncingList.filter(item => item.status === 2).length <= 0 &&
                            <div className="flex flex-col w-full  space-y-2 items-center justify-center">
                                <p>No sync process</p>
                            </div>
                        }

                        {!syncingList &&
                            <div className="flex flex-col w-full  space-y-2 items-center justify-center">
                                <p>No sync process</p>
                            </div>
                        }
                    </TabsContent>
                </Tabs>

            </DialogContent >
        </Dialog >
    )
}

