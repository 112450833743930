import { Check, Pencil } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Button } from '../../../core/components/ui/button';

interface IEditableLabel {
    initialText: string
    updateTitle: (text: string) => {}
}

const EditableLabel: React.FC<IEditableLabel> = ({ initialText, updateTitle }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [text, setText] = useState(initialText);

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);
    const handleEditClick = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setIsEditing(true);
    }
    const handleInputChange = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setText(e.target.value);
    }
    const handleInputBlur = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setIsEditing(false);
    }

    const handleKeyUp = (e: any) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            updateTitle(text)
        }
    }

    const updateTitleChange = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        updateTitle(text)
    }

    useEffect(() => {
        setText(initialText)
    }, [initialText])

    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="inline-block relative"
        >
            {!isEditing ? (
                <div className="flex flex-row items-center justify-start text-nowrap text-ellipsis overflow-hidden relative">
                    <p className='text-sm truncate font-bold'>{text}</p>
                    {isHovered && (
                        <Button
                            variant={'outline'}
                            size='icon'
                            onClick={handleEditClick}
                            className="ml-2 text-blue-500 rounded-full absolute right-4"
                        >
                            <Pencil className='size-4' />
                        </Button>
                    )}
                </div>
            ) : (
                <div className='flex flex-row w-full items-center justify-start'>
                    <input
                        type="text"
                        value={text}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                        onKeyUp={handleKeyUp}
                        autoFocus
                        className="border border-gray-300 w-full"
                    />
                    <Button
                        variant={'ghost'}
                        size='icon'
                        onClick={updateTitleChange}
                        className="text-green-500 rounded-full"
                    >
                        <Check className='size-4' />
                    </Button>
                </div>
            )}
        </div>
    );
};

export default EditableLabel;