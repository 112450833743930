import axios from 'axios';
import { getConfig } from './config-handler';
import { fetchUserData, GetToken } from './manager';

const _config = getConfig()

const axiosInstance = axios.create({
    baseURL: `${_config?.REACT_APP_API_URL}/api`
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = GetToken(); // Or however you store your token
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const { status } = error.response;
        const originalRequest = error.config;

        if (status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            await RefreshAuthToken();
            const newToken = GetToken()
            if (newToken) {
                originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
                return axios(originalRequest);
            }
        } else if (status === 403) {
            localStorage.removeItem('token') // deletes token from storage
            localStorage.removeItem('refresh-token') // deletes token from storage
            localStorage.removeItem('company-list') // deletes token from storage
            localStorage.removeItem('active-token') // deletes token from storage
            window.location.href = '/login'
        }
        return Promise.reject(error);
    }
);




export default axiosInstance;


export const getHeaders = (props: any): any => {

    let _headers = {
        headers: {
            'Content-Type': 'application/json',
        },
    }
    if (props) {
        _headers = { ..._headers, ...props }
    }

    return _headers
}

export const RefreshAuthToken = async () => {
    // const dispatch = useDispatch<AppDispatch>()
    const refreshToken = localStorage.getItem('refresh-token')
    const isRemember = !!localStorage.getItem('isRemember');
    const user = fetchUserData()
    try {
        const response = await axiosInstance.put("/auth/refresh", {
            userId: user?.userId,
            refreshToken,
            isRemember
        });
        const newToken = response.data.accessToken;

        localStorage.setItem('company-list', JSON.stringify(response.data.companiesTokenList))
        localStorage.setItem('token', newToken) // deletes token from storage
        localStorage.setItem('refresh-token', response.data.refreshToken) // deletes token from storage

        GetToken()
        return newToken;
    } catch (error) {
        // dispatch(logout())
        localStorage.removeItem('token') // deletes token from storage
        localStorage.removeItem('refresh-token') // deletes token from storage
        localStorage.removeItem('company-list') // deletes token from storage
        localStorage.removeItem('active-token') // deletes token from storage
        window.location.href = '/login'
        return null;
    }
}


