
import { toast } from "react-toastify";


const ToastMiddleware = (store: any) => (next: any) => (action: any) => {
    // if (action.type.endsWith('fulfilled')) {
    //     toast.success((`Success: ${action.payload.message || 'Operation successful'}`))
    // }
    if (action.type.endsWith('rejected') || action.type.endsWith('_ERROR')) {
        toast.error((`${(action.payload ? action.payload : 'An error occurred. Please try again.') || 'Something went wrong'}`), {
            position: "bottom-right",
            autoClose: 6000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            className: 'text-red-600'
        })
    }

    return next(action);
};

export default ToastMiddleware;