import { Ellipsis, Trash2 } from "lucide-react"
import { MouseEvent, useMemo } from "react"
import { useDispatch } from "react-redux"
import { NavLink, useNavigate, useParams } from "react-router-dom"
import { Badge } from "../../../core/components/ui/badge"
import { Button } from "../../../core/components/ui/button"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../../../core/components/ui/dropdown-menu"
import { resetSelectedChat } from "../../../redux-store/reducers/chat-reducers"
import { AppDispatch } from "../../../redux-store/stores/store"
import { ChatService } from "../../../shared/services/chat/chat-service"

export const Titles = (props: any) => {

    const dispatch = useDispatch<AppDispatch>()

    const params = useParams()
    const navigate = useNavigate()

    const chatServiceInstatnce = useMemo(() => new ChatService(), []);

    const handleDeleteChat = (event: MouseEvent) => {
        event.preventDefault()
        event.stopPropagation()

        dispatch(chatServiceInstatnce.DeleteChat(props))
        if (params && parseInt(params['chat-id'] as string) === props.id) {
            dispatch(resetSelectedChat())
            navigate('/home')
        }
    }

    const commandList = [{
        code: "file-only",
        value: "File only"
    }, {
        code: "directory",
        value: "Directory"
    }, {
        code: "web-only",
        value: "Web only"
    }, {
        code: "web-also",
        value: "Web also"
    }, {
        code: "local-only",
        value: "Local only"
    }, {
        code: "fo",
        value: "File only"
    }, {
        code: "d",
        value: "Directory"
    }, {
        code: "wo",
        value: "Web only"
    }, {
        code: "wa",
        value: "Web also"
    }, {
        code: "lo",
        value: "Local only"
    }];
    const replaceTextWithDiv = (value: any): any => {
        const firstWord = value.split(" ")[0]
        const isContain = commandList.filter(item => `/${item.code.toLowerCase()}` === firstWord.toLowerCase())
        if (isContain.length > 0) {
            const newValue = (
                <>
                    {/* <Badge className="rounded-xl">{isContain[0].value}</Badge> */}
                    {value.slice(firstWord.length)}
                </>
            );
            return newValue;
        }
        return value
    }

    return (
        <NavLink to={props.to} className={({ isActive }) => isActive ? "flex flex-row w-full hover:bg-muted-foreground/20 p-2 rounded-md bg-primary/10 font-semibold h-[36px]" : "flex flex-row w-full hover:bg-muted-foreground/20 p-2 rounded-md h-[36px]"}>
            <div className="flex flex-row items-center justify-between w-full h-full text-sm ">
                <div className="w-11/12 line-clamp-1 capitalize">
                    {replaceTextWithDiv(props.chatDetails.name)}
                </div>
                <DropdownMenu>
                    <DropdownMenuTrigger asChild className="">
                        <Button variant="ghost" className="size-5 p-0">
                            <span className="sr-only">Open menu</span>
                            <Ellipsis className="size-3.5" />
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="start">
                        <DropdownMenuItem className="text-red space-x-2"
                            onClick={(event: MouseEvent) => handleDeleteChat(event)}
                        >
                            <Trash2 className="size-5" /> <p>Delete</p>
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            </div>
        </NavLink>
    )
}