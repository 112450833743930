import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChatService } from '../../shared/services/chat/chat-service'

export interface Chat {
    chatDetails: {
        name: string
        tokens: number
        isSelect: boolean
    }
    icon: string
    to: string
    id: number
    status: number
    type: number
    company: number
    createdUser: number,
    recivingResponse: boolean
}

export interface References {
    fileName: string
    fileUrl: string
    thumbnailUrl: string
    contentType: string
}

export interface Message {
    id: number
    questionDetails: {
        text: string
        createdTime: string
        tokens: number | null
        isUser: boolean
        references: References[]
    }
    answerDetails: {
        text: string | null
        createdTime: string | null
        tokens: number | null
        references: null
    }
    messageDetails: {
        text: string | null
        createdTime: string | null
        fileId: number
    }
    chatBox: number
    chatBoxId: number
    company: number
    status: number
    type: number
    updateInfo: any
}

export interface IChatState {
    chatList: Chat[];   // Adjust the type based on your data structure
    messages: Message[];   // Adjust the type based on your data structure
    loading: boolean;
    error: string | null;
    selectedChatId: number | null
    chatSelected: boolean
    newMessage: boolean
}

const initialState: IChatState = {
    chatList: [],
    messages: [],
    loading: false,
    error: null,
    selectedChatId: null,
    chatSelected: false,
    newMessage: false
};
const chatServiceInstance = new ChatService();

const chatSlice = createSlice({
    name: "chat",
    initialState: initialState,
    reducers: {
        selectChat: (state, { payload }) => {
            state.selectedChatId = parseInt(payload)
            state.chatSelected = true
        },
        resetSelectedChat: (state) => {
            state.selectedChatId = null
            state.messages = []
            state.chatSelected = false
        },
        setNewMessageStatus: (state, { payload }) => {
            state.newMessage = payload
        }
    },
    extraReducers: (builder) => {
        // login user
        builder
            .addCase(chatServiceInstance.GetChatList.pending, (state) => {
                state.loading = true;
                state.chatList = []
            })
            .addCase(chatServiceInstance.GetChatList.fulfilled, (state, action) => {
                state.chatList = action.payload;
                state.loading = false;
                state.chatList.forEach(item => {
                    item.to = `/home/c/${item.id}`
                })
                state.chatList = state.chatList.sort((a, b) => b.id - a.id)
            })
            .addCase(chatServiceInstance.GetChatList.rejected, (state, action) => {
                state.error = action.payload as string;
                state.loading = false;
            })

            .addCase(chatServiceInstance.GetMessages.pending, (state) => {
                state.loading = true;
            })
            .addCase(chatServiceInstance.GetMessages.fulfilled, (state, action: PayloadAction<any>) => {
                ;
                state.loading = false;
                action.payload.forEach((item: any) => {
                    item.questionDetails.isUser = true
                })
                state.messages = action.payload
                setNewMessageStatus(false)
            })
            .addCase(chatServiceInstance.GetMessages.rejected, (state, action) => {
                state.error = action.payload as string;
                state.loading = false;
            })

            .addCase(chatServiceInstance.SendMessage.pending, (state) => {
                state.loading = true;
            })
            .addCase(chatServiceInstance.SendMessage.fulfilled, (state, action: PayloadAction<any>) => {
                action.payload.questionDetails.isUser = true
                state.messages.push(action.payload);
                // state.messages
                state.loading = false;
            })
            .addCase(chatServiceInstance.SendMessage.rejected, (state, action) => {
                state.error = action.payload as string;
                state.loading = false;
            })

            .addCase(chatServiceInstance.DeleteMessage.pending, (state) => {
                state.loading = true;
            })
            .addCase(chatServiceInstance.DeleteMessage.fulfilled, (state, action: PayloadAction<any>) => {
                state.messages = state.messages.filter(msg => msg.id !== action.payload.id); // Example: remove the message
                state.loading = false;
            })
            .addCase(chatServiceInstance.DeleteMessage.rejected, (state, action) => {
                state.error = action.payload as string;
                state.loading = false;
            })

            .addCase(chatServiceInstance.DeleteChat.pending, (state) => {
                state.loading = true;
            })
            .addCase(chatServiceInstance.DeleteChat.fulfilled, (state, action: PayloadAction<any>) => {
                state.chatList = state.chatList.filter(chat => chat.id !== action.payload.id); // Example: remove the chat
                state.loading = false;
            })
            .addCase(chatServiceInstance.DeleteChat.rejected, (state, action) => {
                state.error = action.payload as string;
                state.loading = false;
            })
    }
});

export const { selectChat, resetSelectedChat, setNewMessageStatus } = chatSlice.actions;
export default chatSlice.reducer;