import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance, { getHeaders } from ".";
import { FilesModel } from "../models/files-model";
import { FileChunk, UploadChunkResponse, UploadError } from "../models/upload-type"; let makeRandom = (lengthOfCode: number) => {
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
    let text = "";
    for (let i = 0; i < lengthOfCode; i++) {

        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
}

let blockIdList: Map<number, string[]>;


export const uploadChunk = createAsyncThunk<UploadChunkResponse, FileChunk>(
    'upload/uploadChunk',
    async (uploadType: FileChunk, { rejectWithValue }) => {
        const api = axiosInstance
        const formData = new FormData();
        formData.append('files', uploadType.chunk);
        formData.append('count', uploadType.chunkIndex.toString());
        formData.append('noChunks', uploadType.totalChunks.toString());
        formData.append('fileName', uploadType.file ? (uploadType.file as File).name : '');
        formData.append('token', uploadType.token);
        formData.append('contentType', uploadType.file.type);
        formData.append('isCompleted', (uploadType.chunkIndex + 1 === uploadType.totalChunks).toString());
        formData.append('processId', uploadType.processId);

        try {
            const response = await api.post('/FileBoxes/files', formData, {
                ...getHeaders({
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                }), ...{
                    signal: uploadType.signal
                }
            },
            );
            if (!blockIdList.has(uploadType.fileIndex)) {
                blockIdList.set(uploadType.fileIndex, [response.data.message])
            }
            if (blockIdList.has(uploadType.fileIndex)) {
                let arry: any;
                arry = blockIdList.get(uploadType.fileIndex) as string[]
                arry[uploadType.chunkIndex] = response.data.message
                blockIdList.set(uploadType.fileIndex, arry)
            }

            return { fileIndex: uploadType.fileIndex, chunkIndex: uploadType.chunkIndex, totalChunks: uploadType.totalChunks };
        } catch (error: any) {
            const _error: UploadError = { fileIndex: uploadType.fileIndex, message: error.message }
            return rejectWithValue(_error);
        }
    }
);

export const finalizeUpload = createAsyncThunk<{ data: FilesModel, fileName: string, fileIndex: number }, { fileIndex: number; fileName: string; token: string; contentType: string; blockIdList: string[], processId: string }>(
    'upload/finalizeUpload',
    async ({ fileIndex, fileName, token, contentType, blockIdList, processId }, { rejectWithValue }) => {
        const api = axiosInstance

        try {
            const response = await api.post<FilesModel>(
                '/FileBoxes/commit-files',
                { blockIdList, fileIndex, fileName, token, contentType, processId },
                getHeaders(null)
            );

            return { data: response.data, fileName, fileIndex }; // Assuming `data` is shaped like `FilesModel`
        } catch (error: any) {
            return rejectWithValue({ fileIndex, message: error.response?.data?.message || error.message });
        }
    }
);


export const uploadFilesInChunks = createAsyncThunk<void, { files: FilesModel[]; controller: AbortController | null }>(
    'upload/uploadFilesInChunks',
    async ({ files, controller }, { dispatch }) => {
        const chunkSize = 1024 * 1024; // 1 MB
        const tokens: string[] = [];
        blockIdList = new Map();

        // Create a list of all promises for chunk uploads
        const uploadPromises = files.flatMap((file) => {
            const totalChunks = Math.ceil(file.size / chunkSize);
            const token = makeRandom(8);
            tokens[file.index] = token;
            return Array.from({ length: totalChunks }, (_, chunkIndex) => {
                const chunk = file.slice(chunkIndex * chunkSize, (chunkIndex + 1) * chunkSize);
                return dispatch(uploadChunk({
                    fileIndex: file.index, file, chunk, chunkIndex, totalChunks, token, processId: file.processId,
                    signal: controller ? controller.signal : null,
                })).unwrap();
            });
        });

        // Wait for all chunk uploads to complete
        await Promise.all(uploadPromises).then(() => {
            // After all chunks are uploaded, finalize the upload
            files.flatMap((file) => {
                const fileName = file.name;
                const token = tokens[file.index]
                return dispatch(finalizeUpload({ fileIndex: file.index, fileName, token, contentType: file.type, blockIdList: blockIdList.get(file.index) as string[], processId: file.processId }));
            });
        })
    }
);



export const completeUpload = createAsyncThunk(
    'upload/complete',
    async (request: any, { rejectWithValue }) => {
        const api = axiosInstance
        try {
            // configure header's Content-Type as JSON

            const { data } = await api.put(
                '/fileboxes/upload-files',
                request,
                getHeaders(null)
            )

            return data
        } catch (error: any) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)