import { Check, Eye, Trash2 } from "lucide-react"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import DateDisplay from "../../../core/components/common/date-display/date-display"
import { Button } from "../../../core/components/ui/button"
import { Card, CardContent } from "../../../core/components/ui/card"
import { cn } from "../../../core/lib/utils"
import { FilesModel } from "../../models/files-model"
import EditableLabel from "../editable-label/editable-label"
import FileViewer from "../file-viewer/file-viewer"

export interface ImageThumbnailReq {
    index: number
    item: FilesModel
    selectionChanged: (item: FilesModel | File) => void
    showDelete: boolean
    deleteFile: (item: FilesModel) => void
    updateTitle: (item: FilesModel, text: string) => void
    showSize?: boolean
}

export const ImageThumbnail: React.FC<ImageThumbnailReq> = ({ index, item, selectionChanged, showDelete, deleteFile, updateTitle, showSize = false }) => {
    const navigate = useNavigate()

    const navigateToFileViewer = (fileId: number) => {
        if (fileId) {
            navigate(`/preview/${fileId}`)
        }
    }

    const handleRemoveFile = () => {
        deleteFile(item)
    }

    const handleSelect = () => {
        selectionChanged({ ...item, ...{ isSelected: !item.isSelected } })
    }

    // eslint-disable-next-line
    useEffect(() => {  // eslint-disable-next-line
        item = { ...item }// eslint-disable-next-line
    }, [item])

    return (<div>
        <Card key={index}
            className={cn(`w-full h-[340px]`, item.isSelected ? 'border-2 border-primary' : '')} x-chunk="dashboard-07-chunk-4">
            <CardContent className="p-0">
                <div className="grid gap-4 relative">
                    <Button onClick={() => handleSelect()} variant={'outline'}
                        size={'icon'}
                        className={cn("absolute top-2 left-2 rounded-full text-primary", item.isSelected ? 'bg-primary text-primary-foreground hover:bg-primary-foreground' : '')} >
                        <Check className="size-4" />
                    </Button>
                    {showDelete &&
                        <Button onClick={handleRemoveFile} variant={'outline'}
                            size={'icon'}
                            className="absolute top-2 right-2 rounded-full text-red-600 hover:text-red-600" >
                            <Trash2 className="size-4" />
                        </Button>
                    }
                    <Button onClick={() => navigateToFileViewer(item.id)} variant={'outline'}
                        size={'icon'}
                        className={cn("absolute right-2 rounded-full ", showDelete ? ' top-12 ' : 'top-2')}>
                        <Eye className="size-4" />
                    </Button>
                    <div className={cn("flex flex-row w-full h-[250px] overflow-auto aspect-square text-center rounded-t-xl justify-center p-1 cursor-pointer", item.isSelected ? 'bg-primary/30' : 'bg-slate-100')} onClick={() => navigateToFileViewer(item.id)}>
                        {
                            item &&
                            <FileViewer className="w-fit max-h-[250px]" isThumbnail={true} file={item} />
                        }
                    </div>
                    <div className="flex flex-col w-full overflow-hidden justify-end px-4 py-2 no-wrap space-y-1">
                        {
                            item.title && <EditableLabel initialText={item.title} updateTitle={async (text) => updateTitle(item, text)} />
                        }

                        <div className="flex flex-row w-full text-xs space-x-3 items-center py-1 text-muted-foreground" >
                            {
                                item.contentType &&
                                <div className="uppercase   ">{item.contentType ? item.contentType.split('/')[1] : item.contentType}</div>
                            }
                            {
                                showSize && item.fileSize &&
                                <p>{item.fileSize}</p>
                            }
                            {
                                item.createdOn &&
                                <DateDisplay date={item.createdOn} />
                            }
                        </div>
                    </div>
                </div>
            </CardContent>
        </Card>
    </div>)
}



