import { createSlice } from '@reduxjs/toolkit'
import { UserState } from '../../shared/models/user-state'
import { forgotPassword, loginAction, resetPassowrd, signup, verifyEmail } from '../../shared/services/api'
import { jwtDecode } from 'jwt-decode'
import { auth } from '../../shared/services/firebase'

const initialState: UserState = {
    loading: false,
    userInfo: localStorage.getItem('token') ? jwtDecode(localStorage.getItem('token') as string) : null, // for user object
    companyInfo: null, // for user object
    token: localStorage.getItem('token') ?? null, // for storing the JWT
    refreshToken: localStorage.getItem('refresh-token') ?? null, // for storing the JWT
    error: null,
    success: false, // for monitoring the registration process.
    extraReducers: {},
    isAuthenticated: !!localStorage.getItem('token'),
    companyId: null,
    clientId: null,
    isRemember: !!localStorage.getItem('isRemember')
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            localStorage.removeItem('token') // deletes token from storage
            localStorage.removeItem('refresh-token') // deletes token from storage
            localStorage.removeItem('company-list') // deletes token from storage
            localStorage.removeItem('active-token') // deletes token from storage
            localStorage.removeItem('persist:dashboard') // deletes token from storage
            localStorage.removeItem('persist:upload') // deletes token from storage
            state.loading = false
            state.userInfo = null
            state.companyInfo = null
            state.token = null
            state.error = null
            state.isAuthenticated = false
            state.companyId = null
            state.clientId = null
            auth.signOut().then(() => {
                console.log('logged out from fire')
            })
        },
        resetSucess: (state) => {
            state.success = false
        }
    },
    extraReducers: (builder) => {
        // login user
        builder
            .addCase(loginAction.pending, (state) => {
                state.loading = true
                state.error = null
            })
            .addCase(loginAction.fulfilled, (state, { payload }) => {
                state.loading = false
                state.userInfo = jwtDecode(payload.accessToken)
                state.token = payload.token
                state.refreshToken = payload.refreshToken
                state.isAuthenticated = true
                state.isRemember = !!localStorage.getItem('isRemember')
            })
            .addCase(
                loginAction.rejected, (state, action) => {
                    state.loading = false
                    state.error = action.error.message || 'Failed to login'
                })

            .addCase(signup.pending, (state) => {
                state.loading = true
                state.error = null
            })
            .addCase(signup.fulfilled, (state, { payload }) => {
                state.loading = false
                state.success = payload.success
            })
            .addCase(
                signup.rejected, (state, action) => {
                    state.loading = false
                    state.error = action.error.message || 'Failed to login'
                })

            .addCase(verifyEmail.pending, (state) => {
                state.loading = true
                state.error = null
            })
            .addCase(verifyEmail.fulfilled, (state, { payload }) => {
                state.loading = false
                state.success = payload.success
            })
            .addCase(
                verifyEmail.rejected, (state, action) => {
                    state.loading = false
                    state.error = action.error.message || 'Failed to login'
                })

            .addCase(resetPassowrd.pending, (state) => {
                state.loading = true
                state.error = null
            })
            .addCase(resetPassowrd.fulfilled, (state, { payload }) => {
                state.loading = false
                state.success = payload.success
            })
            .addCase(resetPassowrd.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to login'
            })

            .addCase(forgotPassword.pending, (state) => {
                state.loading = true
                state.error = null
                state.success = false
            })
            .addCase(forgotPassword.fulfilled, (state, { payload }) => {
                state.loading = false
                state.success = payload.success
            })
            .addCase(forgotPassword.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to login'
            })
    }
})

export const { logout, resetSucess } = authSlice.actions
export default authSlice.reducer