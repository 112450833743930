import { createSlice } from '@reduxjs/toolkit';
import { createTeam, getTeams, updateTeam, deleteTeam } from '../../shared/services/teamService';
import { Team, UserGroupModel } from '../../shared/models/team-model';
import { any } from 'zod';

interface TeamState {
    loading: boolean;
    error: string | null;
    teams: Team[];
    success: boolean;
}

const initialState: TeamState = {
    loading: false,
    error: null,
    teams: [],
    success: false,
};

const teamSlice = createSlice({
    name: 'team',
    initialState,
    reducers: {
        resetState: (state) => {
            state.loading = false;
            state.error = null;
            state.success = false;
        },

        resetSuccess: (state, { payload }) => {
            state.success = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            // Fetch Teams
            .addCase(getTeams.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(getTeams.fulfilled, (state, { payload }) => {
                state.teams = payload;
                state.loading = false;
                state.success = true;
            })
            .addCase(getTeams.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch teams';
            })

            // Add Team
            .addCase(createTeam.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(createTeam.fulfilled, (state, { payload }) => {
                state.teams.push(payload);
                state.loading = false;
                state.success = true;
            })
            .addCase(createTeam.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to add team';
            })

            // Update Team
            .addCase(updateTeam.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(updateTeam.fulfilled, (state, { payload }) => {
                const teamIndex = state.teams.findIndex(team => team.id === payload.groupId);
                if (teamIndex !== -1) {
                    const team = state.teams[teamIndex];

                    if (payload.groupName && team.groupName !== payload.groupName) {
                        team.groupName = payload.groupName;
                    }
                    
                    if (payload.addUserGroupModel) {
                        payload.addUserGroupModel.forEach((newUserGroupModel : any) => {
                            const exists = team.userGroupModels?.some(user => user.userId === newUserGroupModel.userId);
                            if (!exists) {
                                if (!team.userGroupModels) {
                                    team.userGroupModels = []; 
                                }
                                team.userGroupModels.push(newUserGroupModel);
                            }
                        });
                    }
                    if (payload.removeUserGroupModel) {
                        payload.removeUserGroupModel.forEach((userToRemove:any) => {
                            team.userGroupModels = team.userGroupModels?.filter(user => user.userId !== userToRemove.userId);
                        });
                    }

                    state.teams[teamIndex] = team;
                }
                state.loading = false;
                state.success = true;
            })

            .addCase(updateTeam.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to update team';
            })

            // Delete Team
            .addCase(deleteTeam.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(deleteTeam.fulfilled, (state, { payload }) => {
                state.teams = state.teams.filter(team => team.id !== payload);
                state.loading = false;
                state.success = true;
            })
            .addCase(deleteTeam.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to delete team';
            });
    },
});

export const { resetState, resetSuccess } = teamSlice.actions;
export default teamSlice.reducer;
