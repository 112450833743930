import { Progress } from "../../../core/components/ui/progress"

// import { useDispatch } from "react-redux"
// import { Button } from "../../../core/components/ui/button"
// import { AppDispatch } from "../../../redux-store/stores/store"
// import { getStopFolderSync } from "../../services/userSetting"
// import { Badge } from "../../../core/components/ui/badge"
// import { RefreshCwOffIcon } from "lucide-react"

export const SyncProgess = ({ passedCount, failedCount, totalCount }: { passedCount: number, failedCount: number,  totalCount: number }) => {

    // const dispatch = useDispatch<AppDispatch>()

    // const stopSync = (event: any, processId: number): void => {
    //     event.preventDefault()
    //     event.stopPropagation()
    //     dispatch(getStopFolderSync(processId))
    // }

    return (
        <div className="flex flex-row w-full space-y-1 justify-between space-x-3">
            <div className="flex flex-col w-full space-y-2">
                <Progress value={(passedCount / totalCount) * 100} className="" />
                <div className="flex flex-row w-full justify-between items-start">
                    <div className="flex flex-col w-full text-sm">
                        <p>{passedCount} /<strong>{totalCount}</strong></p>
                        <div className="flex flex-row space-x-2">
                            {failedCount ?
                                <p className="text-xs text-red-600">{failedCount} failed to sync</p> : ""
                            }
                        </div>
                    </div>
                    {/* <div>
                        {
                            props.status !== 2 && props.status !== 1 &&
                            <Button onClick={(event) => stopSync(event, props.id)} size={'icon'} className="bg-secondary rounded-full">
                                <RefreshCwOffIcon className="size-5" />
                            </Button>
                        }
                    </div> */}
                </div>
            </div>

        </div>
    )
}