import { useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../../redux-store/stores/store"
import { SyncProgess } from "../../../shared/components/folder-sync/syncing-progress"
import { getAllSyncStatus } from "../../../shared/services/userSetting"

export const SyncStatusView = () => {
    const { syncingList } = useSelector((state: RootState) => state.userSetting)
    const dispatch = useDispatch<AppDispatch>()
    const getConnectedDriveCalled = useRef(false);

    useEffect(() => {
        if (!getConnectedDriveCalled.current) {
            dispatch(getAllSyncStatus(null))
            getConnectedDriveCalled.current = true;
        }
    }, [])

    return (
        <div className="flex w-full p-4 rounded-xl shadow-xl bg-card">
            {syncingList &&
                syncingList.map((item, index) => (
                    <div key={index} className="flex flex-row w-full">
                        <SyncProgess {...item} />
                    </div>
                ))
            }
        </div>
    )
}