import { Avatar, AvatarFallback, AvatarImage } from "../../../core/components/ui/avatar"
import { ScrollArea, ScrollBar } from "../../../core/components/ui/scroll-area"
import { cn } from "../../../core/lib/utils"
import icon from '../../../assets/icons/icon.png'
import { useSelector } from "react-redux"
import { RootState } from "../../../redux-store/stores/store"
import { Badge } from "../../../core/components/ui/badge"
import { BookCheck, Folder, Newspaper } from "lucide-react"
import MarkdownPreview from '@uiw/react-markdown-preview';
import { Button } from "../../../core/components/ui/button"
import FileIconViewer from "../../../shared/components/file-icon-viewer/file-icon-viewer"

const LinkRenderer: React.FC<React.AnchorHTMLAttributes<HTMLAnchorElement>> = ({ href, children, ...props }) => {
    return (
        <a href={href} target="_blank" rel="noopener noreferrer" {...props}>
            {children}
        </a>
    );
};


export const ChatBubble = (props: any) => {

    const { userInfo } = useSelector((state: RootState) => state.auth)
    const commandList = [{
        code: "file-only",
        value: "File only:"
    }, {
        code: "directory",
        value: "Directory:"
    }, {
        code: "web-only",
        value: "Web only:"
    }, {
        code: "web-also",
        value: "Web also:"
    }, {
        code: "local-only",
        value: "Local only:"
    }, {
        code: "fo",
        value: "File only:"
    }, {
        code: "d",
        value: "Directory:"
    }, {
        code: "wo",
        value: "Web only:"
    }, {
        code: "wa",
        value: "Web also:"
    }, {
        code: "lo",
        value: "Local only:"
    }];
    const replaceTextWithDiv = (value: string): string => {
        console.log(value)
        commandList.forEach(item => {
            if (value && value.includes(`/${item.code}`)) {
                value = value.replace(`/${item.code}`, `\` ${item.value} \``)
            }
        })
        return value
    }

    return (
        <div className="flex flex-row space-x-4 md:space-x-8  w-full">
            {!props.isUser &&
                <div className="">
                    <Avatar className="size-8 border-2 border-primary/60 bg-card items-center justify-center">
                        <span>
                            <AvatarImage src={icon} alt="Avatar" />
                            <AvatarFallback>SF</AvatarFallback>
                        </span>
                    </Avatar>
                </div>
            }
            <div className={cn("flex flex-col max-w-[75%]  items-center justify-start", props.isUser ? "ml-auto bg-primary text-primary-foreground px-4 py-2 rounded-l-2xl rounded-br-2xl" : "bg-card px-4 py-2 rounded-bl-2xl rounded-r-2xl  w-full")}>
                {
                    !props.isUser &&
                    <div className="flex flex-row w-full space-x-2 items-start leading-none py-2">
                        <BookCheck className="size-4" />
                        <span>Answer</span>
                    </div>
                }
                <MarkdownPreview
                    className="w-full markdown-preview"
                    source={replaceTextWithDiv(props.text)}
                    style={{ backgroundColor: "transparent", color: props.isUser ? 'white' : 'black', }}
                    components={{
                        a: LinkRenderer,  // Override the 'a' tag to use the custom LinkRenderer
                    }} />
                {
                    props.references && props.references.length > 0 &&
                    <div className="flex flex-col py-4 space-y-2 w-full bg-card rounded-2xl">
                        <Badge className="w-fit px-3 rounded-xl space-x-2">
                            <Newspaper className="size-4" />
                            <p>Sources</p>
                        </Badge>
                        <ScrollArea>
                            <div className="flex flex-col space-y-3 overflow-auto max-h-[300px] h-full w-full py-2">
                                {props.references.map((_item: any, index: number) =>
                                (

                                    <div key={`reference-${index}`} className="text-wrapp-2 rounded-2xl bg-muted space-x-2 flex flex-row w-full h-full shadow-md p-2  hover:cursor-pointer"

                                    >   <div className="flex flex-row w-full gap-2" onClick={() => { window.open(_item.fileUrl, '_blank'); }}>

                                            <div className="flex-shrink-0 w-[50px] h-[50px]">
                                                <FileIconViewer
                                                    className="w-full h-full"
                                                    file={_item}
                                                />
                                            </div>
                                            <div className="flex flex-col space-y-1 w-[70%]">
                                                <p className="text-sm font-semibold italic break-words">{_item.fileName}</p>
                                                <p className="text-xs">{_item.filePath}</p>
                                            </div>
                                        </div>
                                        <div className="flex flex-row items-center justify-center space-x-2">
                                            <Button size={"icon"} variant="outline" className="rounded-full" onClick={(e) => { e.preventDefault(); e.stopPropagation(); window.open(_item.folderUrl, '_blank'); }}><Folder className="size-4" /></Button>
                                            {/* <DropdownMenu>
                                                <DropdownMenuTrigger asChild>
                                                    <Button variant="ghost" className="h-8 w-8 p-0">
                                                        <span className="sr-only">Open menu</span>
                                                        <DotsHorizontalIcon className="h-4 w-4" />
                                                    </Button>
                                                </DropdownMenuTrigger>
                                                <DropdownMenuContent align="end">
                                                    <DropdownMenuItem onClick={() => { }}>
                                                        Search from this {_item.type === 1 ? 'folder' : 'file'}
                                                    </DropdownMenuItem>
                                                </DropdownMenuContent>
                                            </DropdownMenu> */}
                                        </div>
                                    </div>
                                )
                                )}
                            </div>
                            <ScrollBar />
                        </ScrollArea>
                    </div>
                }
            </div>
            {props.isUser &&
                <div className="">
                    <Avatar className="size-8 border-2 border-primary/60 bg-card items-center justify-center">
                        <span>
                            <AvatarImage src={userInfo?.profilePicture} alt="Avatar" />
                            <AvatarFallback className="bg-transparent uppercase">{userInfo?.userName?.substring(0, 2)}</AvatarFallback>
                        </span>
                    </Avatar>
                </div>
            }
        </div>
    )
}