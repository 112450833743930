import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { fileTypeFromBuffer } from 'file-type';
import { cn } from '../../../core/lib/utils';
import { ScrollArea } from '../../../core/components/ui/scroll-area';
import { FilesModel } from '../../models/files-model';
import { LoadFilePreview } from '../../services/dashboard';



pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

interface FileViewerProps {
    file?: FilesModel;
    className?: string
    height?: number
    width?: number
    isThumbnail?: boolean
}

const FileViewer: React.FC<FileViewerProps> = ({ file, className, height, width, isThumbnail = false }) => {
    const [fileType, setFileType] = useState<string | null>(null);
    const [fileSrc, setFileSrc] = useState<string | null>(null);

    const [numPages, setNumPages] = useState<number | null>(null);

    const loadPdfFile = async (fileId: number) => {
        const buffer = await LoadFilePreview({ fileId })
        const type = await fileTypeFromBuffer(new Uint8Array(buffer));
        const blob = new Blob([buffer], { type: type?.mime });
        const src = URL.createObjectURL(blob);
        setFileSrc(src);
    }

    useEffect(() => {
        if (file) {
            if (file.type) {
                setFileSrc(file.preview);
                setFileType(file.type);
            } else {
                const url = isThumbnail ? file.thumbnailUrl : file.info.path;
                isThumbnail ? setFileType(file.contentType) : setFileType(file.info.contentType)
                if (!isThumbnail && file.info.contentType === 'application/pdf') {
                    loadPdfFile(file.id)
                } else {
                    setFileSrc(url);
                }
            }
        }
        // eslint-disable-next-line
    }, [file]);

    if (!fileType || !fileSrc) {
        return <div>Loading...</div>;
    }

    switch (fileType) {
        case 'application/pdf':
            if (isThumbnail && file?.thumbnailUrl) {
                return (<img className={cn('object-contain object-center', className)} src={fileSrc} alt="file" />)
            } else {
                if (isThumbnail) {
                    return (<Document file={fileSrc} className={cn('w-full', className)} >
                        < Page pageIndex={0} height={height} width={width} />
                    </Document >)
                } else {
                    return (
                        <ScrollArea>
                            <Document file={fileSrc} className={cn('space-y-4 py-2', className)} onLoadSuccess={({ numPages }) => setNumPages(numPages)} >
                                {
                                    Array.apply(null, Array(numPages))
                                        .map((x, i) => i + 1)
                                        .map((page, index) => <Page key={index} pageNumber={page} height={height} width={width} />)
                                }
                            </Document>
                        </ScrollArea>)
                }

            }
        case 'image/jpg':
        case 'image/jpeg':
        case 'image/png':
        case 'image/avif':
        case 'image/webp':
            return <img className={cn('object-contain object-center', className)} src={fileSrc} alt="file" loading='lazy' />;
        case 'text/plain':
            return (
                <iframe
                    src={fileSrc}
                    title="file"
                    style={{ width: '100%', height: '500px' }}
                    className={cn('', className)}
                />
            );
        default:
            return <div>Unsupported file type</div>;
    }
};

export default FileViewer;
