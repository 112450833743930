import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function makeRandom(lengthOfCode: number) {
  let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
  let text = "";
  for (let i = 0; i < lengthOfCode; i++) {

    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}