import { createSlice } from '@reduxjs/toolkit';
import { makeRandom } from '../../core/lib/utils';
import { FilesModel } from '../../shared/models/files-model';
import { UploadState } from '../../shared/models/upload-type';
import { completeUpload, finalizeUpload, uploadChunk, uploadFilesInChunks } from '../../shared/services/upload';

const initialState: UploadState = {
    uploading: false,
    progress: [],
    errors: [],
    completedFiles: [],
    uploadStatus: [],
    pendingFiles: [],
    selectedFiles: [],
    selectAll: false,
    processId: localStorage.getItem('processId') ?? '',
    loading: false,
    success: false,
    error: null
}

const uploadSlice = createSlice({
    name: 'upload',
    initialState: initialState,
    reducers: {
        resetState: (state) => {
            state.uploading = false;
            state.progress = [];
            state.errors = [];
            state.completedFiles = [];
            state.uploadStatus = [];
        },
        updatePendingFiles: (state, action) => {
            state.pendingFiles = action.payload
        },
        updatePendingFile: (state, action) => {
            state.pendingFiles[action.payload.index] = action.payload
        },
        resetPendingFiles: (state) => {
            state.pendingFiles = []
        },
        selectAllUpdate: (state, action) => {
            state.completedFiles.forEach(item => {
                item.isSelected = action.payload
            })
            state.selectedFiles = state.completedFiles.filter(item => item.isSelected === true)
            state.selectAll = action.payload
        },
        pushSelectedFile: (state, action) => {
            state.completedFiles[action.payload.index].isSelected = action.payload.isSelected

            state.selectedFiles = state.completedFiles.filter(item => item.isSelected === true)

            state.selectAll = state.selectedFiles.length === state.completedFiles.length

        },
        discardSelecteFiles: (state) => {
            state.completedFiles = state.completedFiles.filter(item => item.isSelected === false)
            state.selectedFiles = state.completedFiles.filter(item => item.isSelected === false)
        },
        deleteSelecteFiles: (state, action) => {
            state.completedFiles = state.completedFiles.filter(item => item.index !== action.payload.index)
            state.selectedFiles = state.completedFiles.filter(item => item.isSelected === true)
            state.selectAll = !(state.selectedFiles.length === 0)
        },
        resetSelectedFiles: (state) => {
            state.selectedFiles = []
        },
        setInvertSelection: (state) => {
            state.completedFiles.forEach(item => {
                item.isSelected = !item.isSelected
            })

            state.selectedFiles = state.completedFiles.filter(item => item.isSelected === true)
        },
        selectNextPrevFile: (state, { payload }) => {
            state.completedFiles.forEach(item => item.isSelected = false)
            state.completedFiles[payload.index].isSelected = true
            state.selectedFiles = state.completedFiles.filter(item => item.isSelected === true)
        },
        updateTitle: (state, { payload }) => {
            const { fileId, title } = payload;
            state.completedFiles = state.completedFiles.map(item => item.id === fileId ? { ...item, title } : item)
            state.selectedFiles = state.completedFiles.filter(item => item.isSelected === true)
        },
        resetUploadSuccess: (state, { payload }) => {
            state.success = payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(uploadFilesInChunks.pending, (state) => {
                state.uploading = true;
                state.progress = [];
                state.errors = [];
                state.uploadStatus = [];
                state.processId = localStorage.getItem('processId') ?? makeRandom(8)
                localStorage.setItem('processId', state.processId)
                let index = state.completedFiles.length
                state.pendingFiles.forEach((file: FilesModel) => {
                    file.processId = state.processId
                    file.index = index
                    index++
                })
                state.completedFiles = [...state.completedFiles, ...state.pendingFiles]
            })
            .addCase(uploadChunk.fulfilled, (state, action) => {
                const { fileIndex, chunkIndex, totalChunks } = action.payload;

                if (!state.progress[fileIndex]) {
                    state.progress[fileIndex] = 0;
                }

                state.progress[fileIndex] = ((chunkIndex + 1) / totalChunks) * 100;


            })
            .addCase(uploadChunk.rejected, (state, action) => {
                const { fileIndex, message } = action.payload as { fileIndex: number; message: string };
                state.errors[fileIndex] = message;
                state.uploading = false;

            })

            .addCase(finalizeUpload.fulfilled, (state, action) => {
                const { fileIndex } = action.meta.arg;
                state.uploadStatus[fileIndex] = 'Completed';
                state.completedFiles[fileIndex] = { ...state.completedFiles[fileIndex], ...action.payload.data }
                state.pendingFiles = state.pendingFiles.filter(file => file.index !== fileIndex)
                state.selectedFiles = state.completedFiles.filter(item => item.isSelected === true)
            })
            .addCase(finalizeUpload.rejected, (state, action) => {
                const { fileIndex, message } = action.payload as { fileIndex: number; message: string };
                state.uploadStatus[fileIndex] = `Failed: ${message}`;
                state.uploading = false;
            })
            .addCase(uploadFilesInChunks.fulfilled, (state, action) => {
                state.uploading = false;
            })

            .addCase(completeUpload.pending, (state) => {
                state.loading = true
                state.error = null
                state.success = false
            })
            .addCase(completeUpload.fulfilled, (state, { payload }) => {
                state.loading = false
                state.success = payload ? true : false

            })
            .addCase(completeUpload.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to login'
                state.success = false
            });
    },
});

export const { resetState, selectNextPrevFile, updateTitle, updatePendingFiles,resetUploadSuccess, deleteSelecteFiles, resetPendingFiles, pushSelectedFile, resetSelectedFiles, updatePendingFile, selectAllUpdate, setInvertSelection, discardSelecteFiles } = uploadSlice.actions
export default uploadSlice.reducer;