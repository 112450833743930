import { Button } from "../../core/components/ui/button"
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from "../../core/components/ui/card"
import { Input } from "../../core/components/ui/input"
import { Label } from "../../core/components/ui/label"

import { NavLink } from "react-router-dom"
import { signup } from "../../shared/services/api"
import { useForm } from "react-hook-form"
import { useSelector, useDispatch } from "react-redux"
import { RootState, AppDispatch } from "../../redux-store/stores/store"
import { Checkbox } from "../../core/components/ui/checkbox"
import { resetSucess } from "../../redux-store/reducers/auth-reducers"
import logo from '../../assets/images/logo.png'

export default function SignupForm() {
  const { success } = useSelector((state: RootState) => state.auth)
  const dispatch: AppDispatch = useDispatch()
  const { register, handleSubmit, formState: { isDirty, isValid, errors } } = useForm({ mode: "onChange" })

  const submitForm = (payload: any) => {
    payload.agreements = payload.agreements === 'on'
    dispatch(signup(payload))
  }

  const handleSignIn = () => {
    dispatch(resetSucess())
  }

  return (
    <div className="flex flex-col min-h-screen w-full items-center justify-center">
      <div className="flex h-full items-center justify-center">
        <Card className="mx-auto max-w-sm">
          <CardHeader className="flex flex-col w-full text-center items-center justify-center">
            <img src={logo} className="w-[15rem] h-auto" />
            <CardTitle className="text-xl">{success ? 'Confirmation required' : 'Sign Up'}</CardTitle>
            <CardDescription>
              {success ? `A confirmation mail with instructions has been sent to your email address.
                    Follow those instructions to confirm your email address and activate your account.`:
                'Enter your information to create an account'}
            </CardDescription>
          </CardHeader>
          <CardContent>
            {success ?
              <div>
                <div className="mt-4 text-start text-sm">
                  Already have an account?{" "}
                  <NavLink to="/" className="underline" onClick={handleSignIn}>
                    Sign in
                  </NavLink>
                </div>
              </div> :
              (<div>
                <form onSubmit={handleSubmit(submitForm)}>
                  <div className="grid gap-4 space-y-2">
                    <div className="grid gap-2">
                      <Label htmlFor="first-name">Name</Label>
                      <Input className="bg-muted" id="first-name" placeholder="Name" autoFocus {...register('name', {
                        required: 'Name is required',
                        validate: value => value.trim() !== '' || 'Name cannot be only spaces'
                      })} />

                      {errors.name && <p className="text-xs text-red-600">{errors.name.message?.toString()}</p>}
                    </div>
                    <div className="grid gap-2">
                      <Label htmlFor="email">Email</Label>
                      <Input className="bg-muted"
                        id="email"
                        type="email"
                        placeholder="m@example.com"
                        required
                        {...register('email', {
                          required: 'Email is required',
                          validate: value => value.trim() !== '' || 'Email cannot be only spaces'
                        })} />

                      {errors.email && <p className="text-xs text-red-600">{errors.email.message?.toString()}</p>}
                    </div>
                    <div className="grid gap-2">
                      <Label htmlFor="password">Password</Label>
                      <Input className="bg-muted" id="password" type="password" {...register('password', {
                        required: 'Password is required',
                        validate: value => value.trim() !== '' || 'Password cannot be only spaces'
                      })} />

                      {errors.password && <p className="text-xs text-red-600">{errors.password.message?.toString()}</p>}
                    </div>
                    <div className="inline-flex items-end w-full mt-1.5 text-xs space-x-2">
                      <Checkbox {...register('agreements')} required />
                      <div>
                        <span>I agree to the</span>
                        <NavLink className="ml-1 text-primary-500 hover:underline" to='./'>Terms of Service
                        </NavLink>
                        <span> and</span>
                        <NavLink className="ml-1 text-primary-500 hover:underline" to="https://www.aizenit.com/geneaz.html"
                          target="_blank">Privacy Policy
                        </NavLink>
                      </div>
                    </div>
                    <Button type="submit" className="w-full" disabled={!isDirty && !isValid}>
                      Create an account
                    </Button>


                    {/* <Button variant="outline" className="w-full">
                          Login with Google
                        </Button>
                        <Button variant="outline" className="w-full">
                          Login with Microsoft
                        </Button> */}
                  </div>
                </form><div className="mt-4 text-center text-sm">
                  Already have an account?{" "}
                  <NavLink to="/login" className="underline">
                    Sign in
                  </NavLink>
                </div></div>)
            }
          </CardContent>
        </Card>
      </div>
    </div >
  )
}
