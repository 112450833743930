import { AsyncThunk } from "@reduxjs/toolkit"

export interface IChatService {
    DeleteChat: AsyncThunk<any, any, any>
    GetChatList: AsyncThunk<any, any, any>
    GetMessages: AsyncThunk<any, any, any>
    SendMessage: AsyncThunk<any, any, any>
    DeleteMessage: AsyncThunk<any, any, any>
    newMessage: (userId: number, chatId: number, clientWindowName: 'FileUpload' | 'QnA', callback: (arg0: any) => void) => {}
}

export interface Notification {
    id: string;
    icon?: string;
    image?: string;
    title?: string;
    description?: string;
    time: string;
    link?: string;
    useRouter?: boolean;
    read: boolean;
    action: string;
    delete?: boolean;
    companyId?: number;
    clientId?: number;
    notificationCategory: number;
}

export interface FirebaseNotification {
    id: number;
    userId: number;
    companyId: number;
    clientId: number;
    tags: string[];

    title: string;
    message: string;

    notificationType: INotificationTypes;
    notificationPriority: INotificationPriority;
    medium: INotificationMedium;
    clientWindowName: string;
    progressBarStatus: INotificationStatus;
    actions: INotificationActions[];

    isViewed: boolean;
    createdAt: Date;
    updatedAt: Date;
}

export enum INotificationTypes {
    progressbar,
    notification,
    chat,
    windowRefresh
}

export enum INotificationStatus {
    pending,
    inProgress,
    progressed,
}

export interface INotificationActions {
    action: string;
}

export enum INotificationMedium {
    notification,
    pushMessage
}

export enum INotificationPriority {
    info,
    warning,
    critical,
    extraCritical
}







