import { createSlice } from '@reduxjs/toolkit';
import { apiKeyModel } from '../../shared/models/apiKey-model';
import { createAPIKey, deleteAPIKey, getKeyList } from '../../shared/services/apiKeys';

const initialState: apiKeyModel = {
    loading: false,
    error: '',
    apiKeys:[],
    success: false,
    resetSuccess: false,
    progress: null,
}


const integrationSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetState: (state) => {
            state.loading = false;
            state.error = '';
        },

        resetSuccess: (state, { payload }) => {
            state.success = payload
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(createAPIKey.pending, (state) => {
            state.loading = true
            state.error = null
            state.success = false
        })
        .addCase(createAPIKey.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.success = true;

            const exists = state.apiKeys.some(
                (keys) => keys.id === payload.id
            );

            if (!exists) {
                const newkeys = {
                    ...payload
                };
                state.apiKeys?.push(newkeys);
            }
        })
        .addCase(createAPIKey.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || 'Failed to login'
        })

        .addCase(getKeyList.pending, (state) => {
            state.loading = true
            state.error = null
            state.success = false
        })
        .addCase(getKeyList.fulfilled, (state, { payload }) => {
            state.apiKeys = payload;
            state.loading = false;
            state.success = true;
        })
        .addCase(getKeyList.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || 'Failed to login'
        })

        .addCase(deleteAPIKey.pending, (state) => {
            state.loading = true
            state.error = null
            state.success = false
        })
        .addCase(deleteAPIKey.fulfilled, (state, { payload }) => {
            const id = typeof payload === 'object' ? payload.id : payload;
            const filterItem = state.apiKeys.filter(item => item.id !== id);
            state.apiKeys = filterItem;
            state.loading = false;
            state.success = true;
        })
        .addCase(deleteAPIKey.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || 'Failed to login'
        })

    }
})


export const { resetState,
    resetSuccess } = integrationSlice.actions
export default integrationSlice.reducer