import { createSlice } from '@reduxjs/toolkit'
import { connectedIntegration, IntegrationModel } from '../../shared/models/integration-model'
import { addIntegration, deleteIntegration, getAllSyncStatus, getConnectedDrive, getConnectOathCode, getDrive, getFoldersFromDrives, getFoldersFromFolders, getFolderSyncStatus, getStartFolderSync, getStopFolderSync, updateIntergrationName } from '../../shared/services/userSetting'


const initialState: IntegrationModel = {
    loading: false,
    error: '',
    integrationList: [],
    availableList: [],
    connectedDrive: null,
    success: false,
    resetSuccess: false,
    folders: null,
    progress: null,
    newIntegration: null,
    syncingList: [],
    openApiKeyDialog: false,
    uiConfig: null
}


const integrationSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetState: (state) => {
            state.loading = false;
            state.error = '';
        },

        resetSuccess: (state, { payload }) => {
            state.success = payload
        },
        resetSyncList: (state) => {
            state.syncingList = []
        },
        resetNewIntegration: (state) => {
            state.newIntegration = null
        },
        toggleIntergrationEditable: (state, { payload }) => {
            state.integrationList = state.integrationList.map((integration) => {
                if (integration.id === payload) {
                    return { ...integration, editable: !integration.editable };
                }
                return integration;
            });
        },
        setNewName: (state, { payload }) => {
            state.integrationList = state.integrationList.map(item => {
                if (item.id === payload.id) {
                    item.name = payload.name
                }
                return item;
            })
        },
        updateFolders: (state, { payload }) => {
            state.folders = payload
        },
        openCloseApiKeyDialog: (state, { payload }) => {
            state.openApiKeyDialog = payload
        },
        setUiConfig: (state, { payload }) => {
            state.uiConfig = payload
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(getConnectedDrive.pending, (state) => {
                state.loading = true
                state.error = null
                state.integrationList = []
            })
            .addCase(getConnectedDrive.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.integrationList = payload
            })
            .addCase(getConnectedDrive.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to login'
            })

            .addCase(deleteIntegration.pending, (state) => {
                state.loading = true
                state.error = '';
            })
            .addCase(deleteIntegration.fulfilled, (state, { payload }) => {
                state.loading = false;
                const id = typeof payload === 'object' ? payload.id : payload;
                const filterItem = state.integrationList.filter(item => item.id !== id);
                state.integrationList = filterItem;
            })
            .addCase(deleteIntegration.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to delete'
            })

            .addCase(getConnectOathCode.pending, (state) => {
                state.loading = true
                state.error = null
            })
            .addCase(getConnectOathCode.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.connectedDrive = payload
            })
            .addCase(getConnectOathCode.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to login'
            })

            .addCase(getDrive.pending, (state) => {
                state.loading = true
                state.error = null
                state.success = false
            })
            .addCase(getDrive.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.success = true;
                state.loading = false;
                state.availableList = payload
                state.success = true;
            })
            .addCase(getDrive.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to login'
            })

            .addCase(addIntegration.pending, (state) => {
                state.loading = true
                state.error = null
                state.success = false
            })
            .addCase(addIntegration.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.success = true;

                const exists = state.integrationList.some(
                    (integration) => integration.pluginId === payload.pluginId
                );

                if (!exists) {
                    const newIntegration = {
                        ...payload,
                    };
                    state.integrationList?.push(newIntegration);
                    state.newIntegration = newIntegration;
                }
            })
            .addCase(addIntegration.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to login'
            })



            .addCase(getFoldersFromDrives.pending, (state) => {
                state.loading = true
                state.error = null
                state.folders = []
            })
            .addCase(getFoldersFromDrives.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.folders = payload
            })
            .addCase(getFoldersFromDrives.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to login'
            })


            .addCase(getFoldersFromFolders.pending, (state) => {
                state.loading = true
                state.error = null
            })
            .addCase(getFoldersFromFolders.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.folders = payload
            })
            .addCase(getFoldersFromFolders.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to login'
            })


            .addCase(getFolderSyncStatus.pending, (state) => {
                state.loading = true
                state.error = null
            })
            .addCase(getFolderSyncStatus.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.syncingList = payload
            })
            .addCase(getFolderSyncStatus.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to login'
            })


            .addCase(getStartFolderSync.pending, (state) => {
                state.loading = true
                state.error = null
                state.success = false
            })
            .addCase(getStartFolderSync.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.connectedDrive = payload
                state.success = true;
            })
            .addCase(getStartFolderSync.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to login'
            })


            .addCase(getAllSyncStatus.pending, (state) => {
                state.loading = true
                state.error = null
                state.success = false
            })
            .addCase(getAllSyncStatus.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.connectedDrive = payload
                state.success = true;
            })
            .addCase(getAllSyncStatus.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to login'
            })

            .addCase(getStopFolderSync.pending, (state) => {
                state.loading = true
                state.error = null
                state.success = false
            })
            .addCase(getStopFolderSync.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.syncingList = state.syncingList?.filter(item => item.id !== payload) ?? null
                state.success = true;
            })
            .addCase(getStopFolderSync.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to login'
            })


            .addCase(updateIntergrationName.pending, (state) => {
                state.loading = true
                state.error = null
                state.success = false
            })
            .addCase(updateIntergrationName.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.success = true;
            })
            .addCase(updateIntergrationName.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to login'
            })
    }
})


export const { resetState, resetSyncList, resetNewIntegration, toggleIntergrationEditable,
    setNewName, updateFolders, openCloseApiKeyDialog, setUiConfig,
    resetSuccess } = integrationSlice.actions
export default integrationSlice.reducer