import { createAsyncThunk } from "@reduxjs/toolkit"
import axiosInstance from "."
import { SearchRequest } from "../models/search-request"


export const createCompany = createAsyncThunk(
    'company/create',
    async (credentials: any, { rejectWithValue }) => {
        const api = axiosInstance
        try {
            const { data } = await api.post(
                '/company',
                credentials
            )
            return data
        } catch (error: any) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const loadFiles = createAsyncThunk(
    '/api/FileBoxes/list',
    async (request: SearchRequest | null | undefined, { rejectWithValue }) => {
        const api = axiosInstance
        try {
            const { data } = await api.post(
                '/FileBoxes',
                request
            )
            return data
        } catch (error: any) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const loadFileDetails = createAsyncThunk(
    '/api/FileBoxes/details',
    async (request: any, { rejectWithValue }) => {
        const api = axiosInstance
        try {
            const { data } = await api.get(
                `/FileBoxes/${request.fileId}`,
            )
            return data
        } catch (error: any) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const LoadFilePreview = async (request: any,) => {
    const api = axiosInstance
    try {
        const { data } = await api.get(
            `/preview/1/${request.fileId}`,
            { responseType: 'arraybuffer' }
        )
        return data
    } catch (error: any) {
        // return custom error message from API if any
        if (error.response && error.response.data.message) {
            return error.response.data.message
        } else {
            return error.message
        }
    }
}



export const deleteFile = createAsyncThunk(
    '/api/FileBoxes/delete',
    async (request: any, { rejectWithValue }) => {
        const api = axiosInstance
        try {
            const { data } = await api.delete(
                `/FileBoxes/${request.fileId}`,
            )
            return data
        } catch (error: any) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)


export const deleteFiles = createAsyncThunk(
    '/api/FileBoxes/delete',
    async (request: any, { rejectWithValue }) => {
        const api = axiosInstance
        try {
            const { data } = await api.delete(
                `/FileBoxes`,
                request
            )
            return data
        } catch (error: any) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)



export const updateFileTitle = createAsyncThunk(
    '/api/FileBoxes/update-title',
    async (request: any, { rejectWithValue }) => {
        const api = axiosInstance
        try {
            const { data } = await api.put(
                `/FileBoxes`,
                request
            )
            return data
        } catch (error: any) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)



export const searchFiles = createAsyncThunk(
    '/api/FileBoxes/search',
    async (request: any, { rejectWithValue }) => {
        const api = axiosInstance
        try {
            const { data } = await api.get(
                `/search?query=${request.query}`,
            )
            return data
        } catch (error: any) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)
export const resetPassword = createAsyncThunk(
    '/api/FileBoxes/search',
    async (request: any, { rejectWithValue }) => {
        const api = axiosInstance
        try {
            const { data } = await api.get(
                `/search?query=${request.query}`,
            )
            return data
        } catch (error: any) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)