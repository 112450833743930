import { jwtDecode } from "jwt-decode";
import { UserModel } from "../models/user-model";
import { Company } from "../models/user-state";

const GetCompanyList = (tokens: string[]): { companyList: Map<string, Company>, companyTokenList: Map<string, string> } => {
    const companyList = new Map<string, Company>();
    const companyTokenList = new Map<string, string>();
    const user = fetchUserData()
    if (tokens) {
        tokens.forEach(token => {
            const company = jwtDecode(token) as Company;
            const activeCompany = localStorage.getItem('active-token')
            if (!activeCompany && user?.defaultCompany === company.companyId) {
                localStorage.setItem('active-token', token)
            }
            if (activeCompany) {
                company.isActive = (token === activeCompany)
                const companyActived = jwtDecode(activeCompany as string) as Company;
                if (activeCompany && company.companyId === companyActived.companyId) {
                    localStorage.setItem('active-token', token)
                }
            }


            if (!companyList.has(company.companyId)) {
                // company.clients = new Map<string, Company>();
                companyList.set(company.companyId, {
                    ...company,
                });
            }
            companyTokenList.set(`${company.companyId}`, token)
        });
    }
    return { companyList, companyTokenList };
}

const fetchUserData = () => {
    const userToken = localStorage.getItem('token')
    return userToken ? jwtDecode(userToken) as UserModel : null;
};


const fetchCompanyToken = (): string[] => {
    const comapanyToken = localStorage.getItem('company-list')
    return comapanyToken ? JSON.parse(comapanyToken) : [];
};

const fetchCompanyList = (): any => {
    const companyTokens = fetchCompanyToken();
    const { companyList } = GetCompanyList(companyTokens);
    return companyList;
}

const isAuth = (): any => {
    return localStorage.getItem('token')
}

const GetCompanyListLength = (): number => {
    const comapanyTokens = JSON.parse(localStorage.getItem('company-list') as string)
    return comapanyTokens ? comapanyTokens.length : 0
}

const GetToken = (): string | undefined | null => {
    const comapanyTokens = JSON.parse(localStorage.getItem('company-list') as string)
    GetCompanyList(comapanyTokens)

    return localStorage.getItem('active-token') ?? (localStorage.getItem('token') ?? null)
}


const SwitchCompnay = (companyId: number) => {
    const list = GetCompanyList(fetchCompanyToken())
    const newCompanyToken = list.companyTokenList.get(`${companyId}`) as string
    localStorage.setItem('active-token', newCompanyToken)
}


export { fetchUserData, fetchCompanyToken, fetchCompanyList, isAuth, GetCompanyList, GetToken, GetCompanyListLength, SwitchCompnay }