import './app.css';
import AuthProvider from "./core/middlewares/authProvider";
import Routes from "./routes";


function App() {
  return (
    <AuthProvider>
      <Routes />
    </AuthProvider>
  );
}

export default App;
