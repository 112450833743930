import axios from "axios";
import { useCallback } from "react";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../../redux-store/reducers/auth-reducers";
import { AppDispatch } from "../../redux-store/stores/store";
import { AuthContextType } from "../../shared/models/auth-context-type";
import { AuthProviderProps } from "../../shared/models/auth-provider-prop";
import { getConfig } from "../../shared/services/config-handler";
import { fetchUserData, GetToken } from "../../shared/services/manager";


const AuthContext = createContext<AuthContextType | undefined>(undefined);

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    // State to hold the authentication token
    const [token, setToken_] = useState<string | null>(localStorage.getItem("token"));
    const [refreshToken, setRefreshToken] = useState<string | null>(localStorage.getItem("refresh-token"));
    const dispatch = useDispatch<AppDispatch>()

    const isRemember = !!localStorage.getItem('isRemember');
    const user = fetchUserData()

    const _config = getConfig()

    const api = axios.create({
        baseURL: _config?.REACT_APP_API_URL,
    });

    const setToken = (newToken: string | null) => {
        setToken_(newToken);
        if (newToken) {
            localStorage.setItem("token", newToken);
        } else {
            localStorage.removeItem("token");
        }
    };

    const setRefreshTokenState = (newRefreshToken: string | null) => {
        setRefreshToken(newRefreshToken);
        if (newRefreshToken) {
            localStorage.setItem("refresh-token", newRefreshToken);
        } else {
            localStorage.removeItem("refresh-token");
        }
    };

    const refreshAuthToken = useCallback(async () => {
        try {
            const response = await api.put("/auth/refresh", {
                userId: user?.id,
                refreshToken,
                isRemember
            });
            const newToken = response.data.accessToken;
            localStorage.setItem('token', newToken) // deletes token from storage
            localStorage.setItem('refresh-token', response.data.refreshToken) // deletes token from storage
            localStorage.setItem('company-list', JSON.stringify(response.data.companiesTokenList))
            GetToken()
            return newToken;
        } catch (error) {
            dispatch(logout())
            return null;
        }
    }, [api, dispatch, refreshToken, isRemember, user])

    useEffect(() => {
        const requestInterceptor = api.interceptors.request.use(
            (config: any) => {
                const _token = GetToken()
                if (_token)
                    setToken(_token)

                if (token) {
                    config.headers["Authorization"] = `Bearer ${token}`;
                }
                return config;
            },
            (error: any) => {
                return Promise.reject(error);
            }
        );

        const responseInterceptor = api.interceptors.response.use(
            (response: any) => {
                return response;
            },
            async (error: any) => {
                const { status } = error.response;
                const originalRequest = error.config;
                let isRefreshing = false;
                let failedQueue: { resolve: (value: unknown) => void; reject: (reason?: any) => void; }[] = [];

                const processQueue = (error: any | null, token = null) => {
                    failedQueue.forEach(prom => {
                        if (error) {
                            prom.reject(error);
                        } else {
                            prom.resolve(token);
                        }
                    });

                    failedQueue = [];
                };

                if (status === 401 && !originalRequest._retry) {
                    if (isRefreshing) {
                        return new Promise(function (resolve, reject) {
                            failedQueue.push({ resolve, reject });
                        }).then(token => {
                            originalRequest.headers['Authorization'] = 'Bearer ' + token;
                            return axios(originalRequest);
                        }).catch(err => {
                            return Promise.reject(err);
                        });
                    }

                    originalRequest._retry = true;
                    isRefreshing = true;

                    return new Promise(function (resolve, reject) {
                        refreshAuthToken().then(newToken => {
                            if (newToken) {
                                axios.defaults.headers['Authorization'] = 'Bearer ' + newToken;
                                originalRequest.headers['Authorization'] = 'Bearer ' + newToken;
                                processQueue(null, newToken);
                                resolve(axios(originalRequest));
                            }
                        }).catch(err => {
                            processQueue(err, null);
                            reject(err);
                        }).finally(() => {
                            isRefreshing = false;
                        });
                    });
                } else if (status === 403) {
                    setToken(null);
                    setRefreshTokenState(null);
                    dispatch(logout())
                }

                return Promise.reject(error);
            }
        );

        return () => {
            axios.interceptors.request.eject(requestInterceptor);
            axios.interceptors.response.eject(responseInterceptor);
        };
    }, [token, refreshToken, api, dispatch, refreshAuthToken]);

    // Memoized value of the authentication context
    const contextValue = useMemo(
        () => ({
            token,
            setToken,
            refreshToken,
            setRefreshTokenState,
            refreshAuthToken,
            logout,
        }),
        [token, refreshToken, refreshAuthToken]
    );

    // Provide the authentication context to the children components
    return (
        <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export default AuthProvider;

