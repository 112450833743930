import { isEmpty } from "lodash"
import { MenuSquare, MessageSquare, Plus, SendHorizontal } from "lucide-react"
import { ChangeEvent, KeyboardEvent, useEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { Button } from "../../core/components/ui/button"
import { Separator } from "../../core/components/ui/separator"
import { Sheet, SheetContent, SheetTrigger } from "../../core/components/ui/sheet"
import { Chat, resetSelectedChat, selectChat, setNewMessageStatus } from "../../redux-store/reducers/chat-reducers"
import { AppDispatch, RootState } from "../../redux-store/stores/store"
import { ChatService } from "../../shared/services/chat/chat-service"
import { getConfig } from "../../shared/services/config-handler"
import { ChatBubble } from "./components/chat-bubble"
import { Titles } from "./components/titles"

import icon from '../../assets/icons/icon.png'
import { ScrollArea } from "../../core/components/ui/scroll-area"
import CommandInput from "../../shared/components/command-input/command-input"


export const ChatLayout = () => {


    const [_prompt, setPrompt] = useState<string>('')

    const dispatch = useDispatch<AppDispatch>()
    const isLoaded = useRef(false);

    const [recivingResponse, setRecivingResponse] = useState(false)

    const chatServiceInstatnce = useMemo(() => new ChatService(), []);

    const { chatList, selectedChatId, messages, chatSelected, newMessage } = useSelector((state: RootState) => state.chat)
    const { userInfo } = useSelector((state: RootState) => state.auth)

    const navigate = useNavigate()
    const params = useParams()

    const messagesEndRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (params && params['chat-id']) {
            dispatch(selectChat(params['chat-id']))
        }
        if (isEmpty(params)) {
            dispatch(resetSelectedChat())
        }// eslint-disable-next-line
    }, [params])

    const handleDialog = () => {
        navigate('/profile/integrations')
    }

    const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            sendMessage();
        }
    }

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPrompt(event.target.value)
    }


    const handleSendMessage = (event: any) => {
        event.preventDefault()
        event.stopPropagation()
        sendMessage()
    }

    const sendMessage = () => {
        const message = {
            id: selectedChatId,
            Question: _prompt
        }
        if (!selectedChatId) {
            dispatch(setNewMessageStatus(true))
        }
        dispatch(chatServiceInstatnce.SendMessage(message))
        setPrompt('')
    }

    const handleNewChat = () => {
        dispatch(resetSelectedChat())
        navigate('/home')
    }

    useEffect(() => {
        if (selectedChatId) {
            dispatch(chatServiceInstatnce.GetMessages({ id: selectedChatId }))

            const config = getConfig()
            setRecivingResponse(false)

            const unsubscribe = chatServiceInstatnce.newMessage(parseInt(userInfo?.userId as string) as number, selectedChatId as number, "QnA", (resp: any) => {
                if (resp.length > 0) {
                    const message = resp[0]
                    switch (message?.ProgressBarStatus) {
                        case 1:
                            setRecivingResponse(true)
                            dispatch(setNewMessageStatus(false))
                            break;
                        case 2:
                            const path = `${config?.REACT_APP_ENV}/User/${userInfo?.userId}/Notifications/${message.id}`
                            message.IsViewed = true
                            chatServiceInstatnce.updateViewedMessage(path, message)
                            dispatch(chatServiceInstatnce.GetMessages({ id: message.ChatboxId }))
                            setRecivingResponse(false)
                            break;
                        default:
                            setRecivingResponse(false)
                            break;
                    }
                }
            })

            return () => unsubscribe()
        }// eslint-disable-next-line
    }, [selectedChatId])


    useEffect(() => {
        if (!isLoaded.current) {
            dispatch(chatServiceInstatnce.GetChatList({}))
            isLoaded.current = true
        }// eslint-disable-next-line
    }, [chatServiceInstatnce, dispatch])

    useEffect(() => {
        if (messages && messages.length === 1 && chatList && newMessage) {
            if (!chatList.find(item => item.id === messages[0].chatBox)) {
                dispatch(chatServiceInstatnce.GetChatList({}))
                navigate(`/home/c/${messages[0].chatBox}`)
            }
        }// eslint-disable-next-line
    }, [messages])

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, []);

    useEffect(() => {
    }, [newMessage]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            scrollToBottom();
        }, 500);

        return () => clearTimeout(timeoutId);
    }, [messages, selectedChatId]);



    return (
        <div className="flex flex-row w-full h-full transition-colors z-0">
            <aside className="flex-shrink-0  w-[260px] p-6 hidden md:flex h-full">
                <div className="flex flex-col w-full h-full md:space-y-4 relative">
                    <div className="pb-2 text-sm font-bold space-y-2">
                        <div className="text-2xl"> Chat History</div>
                        <Separator />
                    </div>
                    <div className="flex flex-col h-full">
                        <ScrollArea>
                            <div className="flex flex-col h-[68vh] overflow-auto">
                                {
                                    chatList.map((item: Chat) => (
                                        <Titles key={`chat-${item.id}`} {...item} />
                                    ))
                                }
                            </div>
                        </ScrollArea>
                    </div>
                    <div className="w-full absolute bottom-2 z-10">
                        <Button size={"lg"} onClick={handleNewChat} className="w-full space-x-2 bg-foreground"><MessageSquare className="size-5" /> <span>Start New Chat</span></Button>
                    </div>
                </div>
            </aside>
            <div className="flex flex-col w-full md:max-w-[calc(100%-260px)] px-10 py-4 bg-white flex-initial h-full">
                <div className="flex flex-col w-full h-full rounded-xl  mx-auto space-y-2 relative">
                    <div className="md:hidden absolute z-10">
                        <Sheet>
                            <SheetTrigger className="rounded-full bg-primary text-primary-foreground p-2">
                                <MenuSquare className="size-5" />
                            </SheetTrigger>
                            <SheetContent side={"left"} >
                                <div className="flex flex-col w-full h-full rounded-xl">
                                    <div className="flex flex-col lg:w-full md:w-[120px] h-full lg:space-y-4 md:space-y-4 relative">
                                        <div className="pb-2 text-sm font-bold space-y-2">
                                            <div> Chat History</div>
                                            <Separator />
                                        </div>
                                        <div className="">
                                            {
                                                chatList.map((item) => (
                                                    <Titles key={`chat-${item.id}`} {...item} />
                                                ))
                                            }
                                        </div>
                                        <div className="w-full absolute bottom-0">
                                            <Button size={"lg"} onClick={handleNewChat} className="w-full space-x-2"><MessageSquare className="size-5" /> <span>Start New Chat</span></Button>
                                        </div>
                                    </div>
                                </div>
                            </SheetContent>
                        </Sheet>

                    </div>
                    {!chatSelected && !newMessage && 
                        <div className="flex flex-col  h-full justify-between items-center w-full">
                            <div className="flex flex-row w-full h-fit justify-center items-center">
                                <div className="flex flex-col h-fit w-fit p-8  rounded-2xl space-y-8">
                                    <div className="flex flex-col items-center justify-center w-full h-full space-y-6">
                                        <img src={icon} className="h-[8rem]" alt="icon" />
                                        <div className="flex flex-col max-w-96 items-center text-center justify-center space-y-6">
                                            <h1 className="text-primary text-3xl">How can I help you today?</h1>
                                            <p className="text-muted-foreground text-xl text-center">
                                                You can use any prompt to query through your cloud storages. To begin you can integrate your cloud storages.</p>
                                        </div>
                                    </div>
                                    <div className="flex flex-row items-center justify-center w-full h-full space-x-5">
                                        <Button size={"lg"} className="space-x-2" onClick={() => handleDialog()}>
                                            <Plus className="size-5" /> <p>Intergrate</p>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {chatSelected &&
                        <div className="flex flex-col w-full  h-[76vh] overflow-y-auto p-4 flex-initial" >
                            {
                                messages.map((item, index) => {
                                    return <div key={index} className="flex flex-col w-full h-fit space-y-4 py-4 flex-initial">
                                        <ChatBubble key={`message-${item.id}-q`} {...item.questionDetails} />
                                        <ChatBubble key={`message-${item.id}-a`} {...item.answerDetails} />
                                    </div>
                                })
                            }
                            {recivingResponse &&
                                <div className="flex flex-row space-x-2 pl-8">
                                    <div className="w-3 h-3 rounded-full bg-black animate-typing delay-0"></div>
                                    <div className="w-3 h-3 rounded-full bg-black animate-typing delay-75"></div>
                                    <div className="w-3 h-3 rounded-full bg-black animate-typing delay-150"></div>
                                </div>}
                            <div ref={messagesEndRef} />
                        </div>
                    }
                    <div
                        className="absolute bottom-2 flex flex-row w-full bg-muted rounded-3xl items-center p-2 border focus-within:ring-1 focus-within:ring-ring"
                    >
                        <CommandInput
                            value={_prompt}
                            handleKeyUp={(value) => handleKeyUp(value)}
                            handleOnChange={(value) => handleOnChange(value)}
                            setPrompt={(value) => setPrompt(value)}
                            recivingResponse={recivingResponse}
                            placeholder="Type your prompt here..."
                            isDropdownAbove={true}
                        />

                        <Button size="icon" className="rounded-full" onClick={(event) => handleSendMessage(event)} disabled={recivingResponse}>
                            <SendHorizontal className="size-5" />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}