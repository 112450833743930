import { Outlet } from "react-router-dom";
import Navbar from "../../components/common/navbar/navbar";


const LogedLayout = () => (
    <div className="dark:bg-boxdark-2 dark:text-bodydark  bg-primary/5">
        <div className="flex h-screen overflow-hidden">
            <div className="flex flex-col h-full w-full">
                <Navbar />
                <main className=" max-w-screen min-h-[calc(100vh_-_theme(spacing.32))] md:min-h-[calc(100vh_-_theme(spacing.18))]">
                    <Outlet />
                </main>
            </div>
        </div>
    </div>
)

export default LogedLayout