import { Check, ChevronLeft, ChevronRight,  PencilIcon, Plus, UploadCloud } from "lucide-react"
import { useRef, useState } from "react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { deleteSelecteFiles, discardSelecteFiles, pushSelectedFile, resetSelectedFiles, resetState, resetUploadSuccess, selectAllUpdate, selectNextPrevFile, setInvertSelection, updatePendingFiles, updateTitle } from "../../../../redux-store/reducers/upload-reducers"
import { AppDispatch, RootState } from "../../../../redux-store/stores/store"
import { ImageThumbnail } from "../../../../shared/components/image-thumbnail/image-thumbnail"
import { FilesModel } from "../../../../shared/models/files-model"
import { completeUpload, uploadFilesInChunks } from "../../../../shared/services/upload"
import { cn } from "../../../lib/utils"
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "../../ui/alert-dialog"
import { Button } from "../../ui/button"
import { Checkbox } from "../../ui/checkbox"
import { Input } from "../../ui/input"
import { Progress } from "../../ui/progress"
import { ScrollArea } from "../../ui/scroll-area"

export const FullScreenDropedViewer = () => {

    const [internalFiles, setInternalFiles] = useState<File[] | null>(null);
    const { pendingFiles, selectedFiles, selectAll, uploading, completedFiles, progress, success } = useSelector((state: RootState) => state.upload);
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()

    const componenetDidMount = useRef(false)

    const [abortController, setAbortController] = useState<AbortController | null>(null);

    const [title, setTitle] = useState('');


    useEffect(() => {
        if (!componenetDidMount.current && pendingFiles.length > 0) {
            setInternalFiles(null)
            const controller = new AbortController();
            setAbortController(controller);
            dispatch(uploadFilesInChunks({ files: pendingFiles, controller }))
            componenetDidMount.current = true
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!uploading && componenetDidMount.current && completedFiles.length > 0) {
            dispatch(resetSelectedFiles())
            dispatch(selectAllUpdate(true))
        }
        // eslint-disable-next-line
    }, [uploading])

    const updateFileSelection = (item: FilesModel) => {
        dispatch(pushSelectedFile(item));
    };

    const invertSelection = () => {
        dispatch(setInvertSelection())
    }

    const toggleSelectAll = () => {
        dispatch(selectAllUpdate(!selectAll));
    };

    const handleDiscard = () => {
        dispatch(selectAllUpdate(false))
        componenetDidMount.current = false
        dispatch(resetSelectedFiles())
        dispatch(resetState())
        dispatch(resetUploadSuccess(false))
        navigate('/home')
    }

    const handleSelectedDiscard = () => {
        dispatch(discardSelecteFiles())
    }

    const handleDeleteFile = (item: FilesModel) => {
        dispatch(deleteSelecteFiles(item))
    }

    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.dataTransfer.files) {
            let currentIndex = completedFiles.length
            const _selectedFiles = [...event.dataTransfer.files].map(file => {
                return Object.assign(file, {
                    preview: URL.createObjectURL(file),
                    contentType: file.type,
                    index: currentIndex++
                });
            });
            setInternalFiles(_selectedFiles);
        }
    };

    const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let currentIndex = completedFiles.length
        const selectedFiles = Array.from(event.target.files || []).map(file => {
            return Object.assign(file, {
                preview: URL.createObjectURL(file),
                contentType: file.type,
                index: currentIndex++
            });
        });
        setInternalFiles(selectedFiles);
    };

    const handleButtonClick = () => {
        fileInputRef.current?.click();
    };

    const handleButtonCancel = () => {
        if (abortController) {
            abortController.abort();
            setAbortController(null);
            dispatch(selectAllUpdate(false))
            dispatch(resetSelectedFiles())
            dispatch(resetState())
            navigate('/home')
        }
    }

    const handleButtonDone = () => {
        if (selectedFiles.length > 0) {
            const req = selectedFiles.map(item => ({ id: item.id, title: item.title }))
            dispatch(completeUpload(req))
        }
    }

    useEffect(() => {
        if (success) {
            dispatch(selectAllUpdate(false))
            dispatch(resetSelectedFiles())
            dispatch(resetState())
            dispatch(resetUploadSuccess(false))
            navigate('/home')
        }
        // eslint-disable-next-line
    }, [success])


    useEffect(() => {
        if (internalFiles) {
            dispatch(updatePendingFiles(internalFiles))
        } // eslint-disable-next-line
    }, [internalFiles, dispatch])

    useEffect(() => {
        if (componenetDidMount.current === true && internalFiles && internalFiles?.length > 0) {
            setInternalFiles(null)
            const controller = new AbortController();
            setAbortController(controller);
            dispatch(uploadFilesInChunks({ files: pendingFiles, controller }))
        }
        // eslint-disable-next-line
    }, [pendingFiles])


    const switchNextPrev = (item: FilesModel) => {
        dispatch(selectNextPrevFile(item));
    }

    const updateTitleHandler = (e: any) => {
        const newTitle = e.target.value;
        setTitle(newTitle);
    }

    const updateKeyUpTitleHandler = (e: any) => {
        const newTitle = e.target.value;
        // Update all selected files with the new title
        selectedFiles.forEach(file => {
            dispatch(updateTitle({ fileId: file.id, title: newTitle }));
        });
    }


    const updateTitleSingleHandler = (item: any, text: string) => {
        const newTitle = text;
        setTitle(newTitle);
        // Update all selected files with the new title
        dispatch(updateTitle({ fileId: item.id, title: newTitle }));

    }


    useEffect(() => {
        if (selectedFiles.length > 0) {
            // Only set the title for a single file selection, or keep it as it is for multiple files
            if (selectedFiles.length === 1) {
                setTitle(selectedFiles[0].title || '');
            }
        }
        // eslint-disable-next-line
    }, [selectedFiles]);


    return (
        <div className="flex flex-col w-full min-h-screen">
            <div className="flex flex-row min-h-[74px] z-10 shadow-lg justify-between px-8 items-center relative">
                {
                    uploading &&
                    <div className="absolute min-h-[74px]  p-0 m-0 left-0  z-10 w-full items-center">
                        <Progress className="min-h-[74px] absolute rounded-none  p-0 m-0 left-0  z-10 w-full" value={parseInt((Object.values(progress).reduce((a, b) => a + b, 0) / completedFiles.length).toFixed(2))} />
                        <p className="h-full w-full absolute text-white text-xl left-8 top-6 z-30 font-bold">Uploading files</p>
                        <Button className="absolute z-30 right-8 top-6" onClick={handleButtonCancel} >
                            close
                        </Button>
                    </div>
                }
                {
                    !uploading &&
                    <div className="flex flex-row w-full h-full">
                        <div className="flex flex-row w-full items-center space-x-3 font-semi-bold ">
                            <Check className="size-6" />
                            <p className={cn("")}>{completedFiles.length === 1 ? `${completedFiles.length} file uploaded` : `${completedFiles.length} files uploaded`} </p>
                        </div>
                        <div className="flex flex-row space-x-3 h-full items-center">
                            <AlertDialog>
                                <AlertDialogTrigger asChild>
                                    <Button variant={'outline'} size={'lg'} className="rounded-3xl ">Discard</Button>
                                </AlertDialogTrigger>
                                <AlertDialogContent>
                                    <AlertDialogHeader>
                                        <AlertDialogTitle>Do you want to discard these files?</AlertDialogTitle>
                                        <AlertDialogDescription>
                                            You can't undo this.
                                        </AlertDialogDescription>
                                    </AlertDialogHeader>
                                    <AlertDialogFooter>
                                        <AlertDialogAction onClick={handleDiscard}>Discard {completedFiles.length} uploaded files</AlertDialogAction>
                                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                                    </AlertDialogFooter>
                                </AlertDialogContent>
                            </AlertDialog>
                            <Button size={'lg'} className="rounded-3xl px-8 py-3" onClick={handleButtonDone}>Done</Button>
                        </div>
                    </div>
                }
            </div>
            <div className="flex flex-row w-full h-full min-h-screen">
                <div className="flex flex-col h-full w-full  min-h-screen  bg-[#ececef]">
                    <div className="flex flex-row w-full min-h-[72px] border-b border-muted-foreground/20 justify-between items-center px-8 py-3">
                        <div className="flex flex-row space-x-3">
                            <div className="flex flex-row items-center space-x-3">
                                <Checkbox id="select all" checked={selectAll} onCheckedChange={toggleSelectAll} className="size-6" />
                                <label
                                    htmlFor="select all"
                                    className="text-sm font-semibold leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                >
                                    {selectedFiles ? selectedFiles.length : 0} of {completedFiles.length} selected
                                </label>
                            </div>
                            {
                                (selectedFiles.length < completedFiles.length && selectedFiles.length > 0) && <Button variant={'ghost'} onClick={invertSelection}>Invert selection</Button>
                            }
                            {
                                selectedFiles.length > 0 &&
                                <AlertDialog>
                                    <AlertDialogTrigger asChild>
                                        <Button variant={'ghost'}>Discard selection</Button>
                                    </AlertDialogTrigger>
                                    <AlertDialogContent>
                                        <AlertDialogHeader>
                                            <AlertDialogTitle>Discard {selectedFiles.length} file from your upload?</AlertDialogTitle>
                                            <AlertDialogDescription>
                                                You can't undo this.
                                            </AlertDialogDescription>
                                        </AlertDialogHeader>
                                        <AlertDialogFooter >
                                            <AlertDialogAction onClick={handleSelectedDiscard}>Discard {selectedFiles.length > 1 ? 'files' : 'file'} </AlertDialogAction>
                                            <AlertDialogCancel>Cancel</AlertDialogCancel>
                                        </AlertDialogFooter>
                                    </AlertDialogContent>
                                </AlertDialog>
                            }
                        </div>
                        <input
                            type="file"
                            multiple
                            onChange={handleFileInputChange}
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            accept="image/png, image/jpeg, image/avif, image/webp, application/pdf, text/plain"
                        />
                        <Button variant={'ghost'} className="" onClick={handleButtonClick} >
                            <Plus className="size-5" />
                        </Button>
                    </div>
                    <div className="flex flex-row w-full max-h-screen h-full pt-6" onDragOver={handleDragOver}
                        onDrop={handleDrop}>
                        <ScrollArea className="flex w-full h-full">
                            {
                                completedFiles.length > 0 &&
                                <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-4 px-9 py-6">
                                    {completedFiles.map((item, index) =>
                                        <ImageThumbnail key={index} index={index} item={item}
                                            selectionChanged={(item) => updateFileSelection(item as FilesModel)} showDelete={true}
                                            deleteFile={(item: FilesModel): void => {
                                                handleDeleteFile(item as FilesModel)
                                            }}
                                            updateTitle={(item, text) => updateTitleSingleHandler(item, text)}
                                            showSize={true}
                                        />
                                    )}
                                </div>
                            }

                            {
                                completedFiles.length === 0 &&
                                (<div className="flex flex-row w-full h-full items-center justify-center px-9 py-3">
                                    <div
                                        className="flex flex-col w-full h-full p-4 bg-slate-100 rounded-2xl items-center justify-center text-center space-y-2"
                                        onDragOver={handleDragOver}
                                        onDrop={handleDrop}
                                    >
                                        <div className="font-bold text-xl">Want to upload more files?</div>
                                        <div className="text-muted-foreground">Drag and drop files onto this page</div>
                                        <div className="flex justify-center text-xs uppercase">
                                            <span className="px-2 text-muted-foreground">or</span>
                                        </div>
                                        {/* <input
                                            type="file"
                                            multiple
                                            onChange={handleFileInputChange}
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                            accept="image/png, image/jpeg, image/avif, image/webp, application/pdf, text/plain"
                                        /> */}
                                        <Button
                                            className="flex flex-row rounded-3xl space-x-3 items-center justify-center px-4"
                                            onClick={handleButtonClick}
                                        >
                                            <UploadCloud className="size-4" />
                                            <p>Browse Files</p>
                                        </Button>
                                    </div>
                                </div>)
                            }
                        </ScrollArea>
                    </div>
                </div>
                <div className="flec flex-col w-[50rem] min-h-svh bg-[#faf9fa] rounded-lg shadow-lg px-9 pt-12 space-x-3">
                    {selectedFiles.length === 1 && completedFiles.length !== selectedFiles.length && (
                        <div className="flex flex-row w-full h-fit justify-between">
                            <Button variant={'ghost'} onClick={() => { switchNextPrev(completedFiles[selectedFiles[0].index - 1]) }} disabled={selectedFiles[0].index === 0}>
                                <ChevronLeft className="size-4" /> Prev
                            </Button>
                            <Button variant={'ghost'} onClick={() => { switchNextPrev(completedFiles[selectedFiles[0].index + 1]) }} disabled={selectedFiles[0].index === completedFiles.length - 1}>
                                <ChevronRight className="size-4" />  Next
                            </Button>
                        </div>
                    )}
                    {
                        selectedFiles.length > 0 ? (

                            < div className="flex w-full flex-col items-start justify-start mb-6">
                                <p className="text-sm font-semibold mb-3">Title</p>
                                <Input
                                    type="text"
                                    className="h-10 w-full"
                                    value={title}
                                    placeholder={selectedFiles.length > 1 ? `Replace ${selectedFiles.length} titles` : ''}
                                    onKeyUp={updateKeyUpTitleHandler}
                                    onChange={updateTitleHandler}
                                />
                            </div>
                        ) :
                            <div className="flex flex-col items-center justify-center h-full min-h-screen space-y-8">
                                <div className="flex size-16 p-4 bg-slate-500/20 items-center justify-center rounded-full">
                                    <PencilIcon className="size-7" />
                                </div>
                                <p className="text-center text-xl font-bold w-60">
                                    Select some assets to start editing
                                </p>
                            </div>
                    }
                </div>
            </div>
        </div >
    )
}