import { format } from "date-fns"
import { ArrowLeft } from "lucide-react"
import { useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import DateDisplay from "../../core/components/common/date-display/date-display"
import { Button } from "../../core/components/ui/button"
import { Separator } from "../../core/components/ui/separator"
import { resetPreviewFile } from "../../redux-store/reducers/dashboard-reducer"
import { AppDispatch, RootState } from "../../redux-store/stores/store"
import FileViewer from "../../shared/components/file-viewer/file-viewer"
import { FilesModel } from "../../shared/models/files-model"
import { loadFileDetails } from "../../shared/services/dashboard"

const FileOverview = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch<AppDispatch>()
    const hasLoaded = useRef(false)
    const searchParams = useParams<{ fileId: string }>();

    const { file } = useSelector((state: RootState) => state.dashboard)

    const navigateBack = () => {
        dispatch(resetPreviewFile())
        navigate(-1)
    }

    useEffect(() => {
        if (!hasLoaded.current) {
            dispatch(loadFileDetails({ fileId: searchParams.fileId }))
            hasLoaded.current = true;
        }
        // eslint-disable-next-line
    }, [searchParams, dispatch])


    return (
        <div className="flex h-full w-full flex-row">
            <div className="flex flex-col z-10 w-full h-full min-h-screen bg-black relative items-center justify-center">
                <Button variant={'outline'} size="icon" className="left-4 top-4 absolute rounded-full" onClick={() => navigateBack()}>
                    <ArrowLeft />
                </Button>

                <FileViewer className="w-fit max-h-screen" file={file as FilesModel} />

            </div>
            <div className="flec flex-col w-[45rem] h-full min-h-screen bg-muted/40 rounded-lg shadow-lg p-2">
                <div className="flex w-full flex-row items-center justify-start p-6">
                    <p className="text-2xl font-extrabold">{file?.info.title}</p>
                </div>
                <Separator />
                <div className="flex w-full flex-row items-center justify-center p-6 space-x-6 font-semibold">
                    <div>{file?.info.fileSize}</div>
                    <p className="text-md font-bold">|</p>
                    <div className="uppercase">{file?.info.contentType.split('/')[1]}</div>
                </div>
                <Separator />
                <div className="flex flex-row w-full p-6">
                    <div className="flex flex-col w-full">
                        <div className="flex flex-row font-bold">
                            <p>Original File</p>
                        </div>
                        <div className="flex flex-row text-muted-foreground">
                            <p>{file?.info.name}</p>
                        </div>
                    </div>
                    {/* <div className="flex flex-col">
                        <Select>
                            <SelectTrigger className="w-[180px] sm:w-full">
                            </SelectTrigger>
                            <SelectContent>
                                <SelectGroup>
                                    <SelectLabel>Fruits</SelectLabel>
                                    <SelectItem value="v">v</SelectItem>
                                    <SelectItem value="banana">Banana</SelectItem>
                                    <SelectItem value="blueberry">Blueberry</SelectItem>
                                    <SelectItem value="grapes">Grapes</SelectItem>
                                    <SelectItem value="pineapple">Pineapple</SelectItem>
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                    </div> */}
                </div>
                <div className="flex flex-row w-full p-6">
                    <div className="flex flex-col w-full">
                        <div className="flex flex-row font-bold">
                            <DateDisplay date={file?.updateInfo?.createdOn} />
                        </div>
                        <div className="flex flex-row space-x-4  text-muted-foreground">
                            <p>By {file?.userName}</p><p>|</p><p>{
                                file?.updateInfo?.createdOn ? format((file?.updateInfo?.createdOn?.toString() as string), 'EEEE, MMMM dd yyyy HH:mm:ss a') : ''
                            }</p>
                        </div>
                    </div>
                </div>
                {/* <div className="flex flex-row w-full p-6">
                    <div className="flex flex-col w-full">
                        <div className="flex flex-row">
                            <p>Original File</p>
                        </div>
                        <div className="flex flex-row">

                        </div>
                    </div>
                    <div className="flex flex-col">

                    </div>
                </div> */}
                <Separator />
                {/* <div className="flex flex-row w-full p-6">
                    <div>Auto Tags</div>
                    <div>

                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default FileOverview