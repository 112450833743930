import { ArrowLeftIcon, FolderIcon } from "lucide-react";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge } from "../../../core/components/ui/badge";
import { Button } from "../../../core/components/ui/button";
import { Checkbox } from "../../../core/components/ui/checkbox";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "../../../core/components/ui/dialog"
import { ScrollArea } from "../../../core/components/ui/scroll-area";
import { resetSuccess } from "../../../redux-store/reducers/userSetting-reducers";
import { AppDispatch, RootState } from "../../../redux-store/stores/store";
import { ChatService } from "../../services/chat/chat-service";
import { getConfig } from "../../services/config-handler";
import { getFoldersFromDrives, getFoldersFromFolders, getStartFolderSync } from "../../services/userSetting";
import { SyncProgess } from "./syncing-progress";

interface FolderSyncDialogProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    data: any
}

export const FolderSyncDialog: FC<FolderSyncDialogProps> = ({ open, setOpen, data }) => {
    const { folders, loading, success } = useSelector((state: RootState) => state.userSetting)
    const dispatch = useDispatch<AppDispatch>()
    const isLoadFolders = useRef(false)

    const [isInSubFolder, setIsInSubFolder] = useState(false)
    const [paths, setPaths] = useState<string[] | null>(null)
    const [selectedPath, setSelectedPath] = useState<string | null>(null)
    const [selectedFolders, setSelectedFolders] = useState<string[] | null>(null)


    const [folderState, setFolderState] = useState<any[] | undefined | null>(folders);

    const chatServiceInstatnce = useMemo(() => new ChatService(), []);
    const { userInfo } = useSelector((state: RootState) => state.auth)


    const handleCheckedChange = (idOrPath: string, e: any) => {
        const updatedFolders = folderState?.map((item) => {
            return item.idOrPath === idOrPath ? { ...item, isSynced: e } : item
        }
        );

        setFolderState(updatedFolders);
        const selectedFilesArray: string[] = []
        updatedFolders?.filter(item => item.isSynced ?? false).forEach(item => selectedFilesArray.push(item.idOrPath))
        setSelectedFolders(selectedFilesArray)
        // dispatch(updateFolders(updatedFolders))
    };


    const updateFoldersSyncProgress = (message: any) => {
        const _updatedFolders = folders?.map((item) => {
            return item.syncId === message.SyncId ? { ...item, passedCount: message.PassedCount, failedCount: message.FailedCount, status: message.SyncStatus, totalCount: message.TotalCount } : item
        }
        );
        setFolderState(_updatedFolders);
    }

    useEffect(() => {
        // console.log(folderState)
    }, [folderState])

    useEffect(() => {
        if (folders) {
            setFolderState(folders)
        }
        if (folders && folderState && folders.filter(item => item.status === 0).length > 0) {
            const config = getConfig();
            const processesToSync = folders.filter(item => item.status === 0);
            const unsubscribeList = processesToSync.map(process => {
                const unsubscribe = chatServiceInstatnce.getSyncStatus(
                    parseInt(userInfo?.userId as string),
                    process.syncId,
                    "SyncWindow",
                    (resp: any) => {
                        if (resp.length > 0) {
                            const message = resp[0];
                            updateFoldersSyncProgress(message)
                            switch (message?.ProgressBarStatus) {
                                case 1:
                                    break;
                                case 2:
                                    const path = `${config?.REACT_APP_ENV}/User/${userInfo?.userId}/Notifications/${message.id}`;
                                    message.IsViewed = true;
                                    chatServiceInstatnce.updateViewedMessage(path, message);
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
                );

                return unsubscribe;
            });

            return () => unsubscribeList.forEach(unsubscribe => unsubscribe());
        }// eslint-disable-next-line 
    }, [folders])

    useEffect(() => {
        if (!isLoadFolders.current && open) {
            dispatch(getFoldersFromDrives(data.id))
            // dispatch(getFolderSyncStatus(data.id))
            isLoadFolders.current = true;
        }
        return () => {
            isLoadFolders.current = false
            setSelectedFolders(null)
            setSelectedPath(null)
            setPaths(null)
            setIsInSubFolder(false)
        }// eslint-disable-next-line 
    }, [open])


    // const handleSelectedFolder = (folder: any) => {
    //     if (!folder.folder) {
    //         return
    //     }
    //     const request = {
    //         pluginId: data.id,
    //         folderId: folder.idOrPath
    //     }
    //     setIsInSubFolder(true)
    //     const newPaths = [...paths ?? [], folder.idOrPath]
    //     setPaths(newPaths)
    //     setSelectedPath(folder.idOrPath)
    //     dispatch(getFoldersFromFolders(request))
    // }

    const handleBack = () => {
        if (paths) {
            const newPaths = paths.filter(item => item !== selectedPath)
            setSelectedPath(newPaths[newPaths.length - 1])
            setPaths(newPaths)
        }
    }


    const startSync = (event: any): void => {
        event.preventDefault()
        event.stopPropagation()
        const request = {
            IntegrationId: data.id,
            FolderIds: selectedFolders
        }
        dispatch(getStartFolderSync(request))
    }

    useEffect(() => {
        if (success) {
            dispatch(resetSuccess(false))
            setOpen(false);
        }// eslint-disable-next-line 
    }, [success])


    useEffect(() => {
        if (paths?.length === 0 && data.id) {
            setIsInSubFolder(false)
            dispatch(getFoldersFromDrives(data.id))
        } else if (paths && paths.length > 0 && data.id) {
            const request = {
                pluginId: data.id,
                folderId: paths[(paths.length - 1)]
            }
            dispatch(getFoldersFromFolders(request))
            setIsInSubFolder(true)
        }// eslint-disable-next-line 
    }, [paths])

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogContent className="w-full md:max-w-[1000px]">
                <DialogHeader>
                    <DialogTitle className="font-bold text-2xl">Sync your folder in {data.name}</DialogTitle>
                    <DialogDescription>Select your folder to sync files into system</DialogDescription>
                </DialogHeader>
                <div className="flex flex-col w-full h-[30rem] max-h-[30rem] space-y-2">
                    <div className="flex flex-row w-full h-full items-start space-x-2">
                        {isInSubFolder &&
                            <Button size="icon" className="rounded-full" onClick={() => handleBack()}>
                                <ArrowLeftIcon className="size-5" />
                            </Button>
                        }
                        <ScrollArea className="h-[25rem] w-full overflow-auto">
                            <div className="flex flex-col w-full px-4 items-start space-y-2">
                                {!loading && folderState &&
                                    (folderState.length > 0 ?
                                        folderState.map((item, index) => (
                                            <div key={index} className="flex flex-row w-60 lg:w-full items-center px-6 py-2 bg-primary/20 rounded-2xl shadow-lg">
                                                <div className="flex flex-col w-full" >
                                                    <span className="flex flex-row items-center justify-between">
                                                        <label className="flex flex-row items-center cursor-pointer w-full capitalize max-w-40  space-x-4" htmlFor={`${item.idOrPath}`} >
                                                            {item.folder &&
                                                                <FolderIcon className="size-5" />
                                                            }
                                                            <p>{item.fileName}</p>
                                                        </label>
                                                        <Checkbox className="ml-auto" id={`${item.idOrPath}`} checked={item.isSynced} onCheckedChange={(e) => handleCheckedChange(item.idOrPath, e)} />
                                                    </span>
                                                    <div className="flex flex-row space-x-2 items-center py-1">
                                                        {item.status === 0 && <SyncProgess {...item} />}
                                                        {
                                                            item.status === 1 &&
                                                            <Badge className="rounded-2xl w-fit text-nowrap bg-green-600 text-xs">Synced</Badge>
                                                        }
                                                        {/* {
                                                            item.status !== 1 && item.status === 2 &&
                                                            <Badge className="rounded-2xl w-fit text-nowrap bg-yellow-600 text-xs">Sync Stoped</Badge>
                                                        } */}
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                        :
                                        <div>
                                            No Folders
                                        </div>)
                                }
                                {loading &&
                                    <div>
                                        Loading...
                                    </div>
                                }
                            </div >
                        </ScrollArea>
                    </div>
                    <div className="flex flex-row w-full items-center justify-end space-x-2">
                        <Button onClick={(event) => startSync(event)} disabled={!selectedFolders}>Save</Button>
                    </div>
                </div >
            </DialogContent >
        </Dialog >
    )
}

