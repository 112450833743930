import { Button } from "../../core/components/ui/button"
import { Input } from "../../core/components/ui/input"
import { Label } from "../../core/components/ui/label"

import { NavLink, useLocation, useNavigate } from "react-router-dom"
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from "../../core/components/ui/card"
import { Separator } from "../../core/components/ui/separator"
import { useForm } from "react-hook-form"
import { AppDispatch, RootState } from "../../redux-store/stores/store"
import { useDispatch, useSelector } from "react-redux"
import { resetPassowrd } from "../../shared/services/api"
import { useEffect } from "react"
import { resetSucess } from "../../redux-store/reducers/auth-reducers"




export default function ResetpasswordAuth() {
    const dispatch: AppDispatch = useDispatch()
    const { success } = useSelector((state: RootState) => state.auth)
    const { register, handleSubmit } = useForm()
    const location = useLocation();
    const navigate = useNavigate()

    const searchParams = new URLSearchParams(location.search);

    const resetpasswordtoken = searchParams.get('id');


    useEffect(() => {
        if (success) {
            setTimeout(
                async function () {
                    await dispatch(resetSucess());
                    navigate('/login')
                },
                3000
            );
        }
    }, [success, dispatch, navigate])

    const handleSignIn = () => {
        dispatch(resetSucess())
    }


    const submitForm = (data: any) => {
        const payload = {
            ...data,
            resetpasswordtoken,
        };
        dispatch(resetPassowrd(payload));
    }

    return (
        <div className="flex flex-col min-h-screen w-full items-center justify-center">
            <div className="flex h-full items-center justify-center">
                <Card className="mx-auto max-w-sm ">
                    <CardHeader>
                        <CardTitle className="text-2xl">Reset your password</CardTitle>
                        <CardDescription>
                            Create a new password for your account
                        </CardDescription>
                    </CardHeader>
                    <CardContent>
                        <form onSubmit={handleSubmit(submitForm)}>
                            <div className="grid gap-4">
                                <div className="grid gap-2">
                                    <Label htmlFor="email">Password</Label>
                                </div>
                                <div className="grid gap-2">
                                    <Input id="password" type="password" />
                                </div>
                                <div className="grid gap-2">
                                    <Label htmlFor="email">Password(Confirm)</Label>
                                </div>
                                <div className="grid gap-2">
                                    <Input id="confirmPassword" type="password"  {...register('newPassword')} />
                                </div>

                                <Button type="submit" className="w-full">
                                    Rest Your Password
                                </Button>
                                <Separator />

                            </div>
                        </form>
                        <div className="mt-4 text-center text-sm">
                            Retun to {" "}
                            <NavLink to="/login" className="underline" onClick={handleSignIn}>
                                Sign in
                            </NavLink>
                        </div>
                    </CardContent>
                </Card>
            </div>

        </div>
    )
}