import { createSlice } from '@reduxjs/toolkit';
import { DashboardModel } from '../../shared/models/dashboard-model';
import { FilesModel } from '../../shared/models/files-model';
import { SearchRequest } from '../../shared/models/search-request';
import { createCompany, deleteFile, loadFileDetails, loadFiles, searchFiles } from '../../shared/services/dashboard';

const initialState: DashboardModel = {
    loading: false,
    files: [],
    error: '',
    file: undefined,
    preview: null,
    suggestions: undefined,
    filter: {
        query: null,
        fileTypes: null,
        orientations: null,
        dateRange: null,
        orderType: null
    } as SearchRequest,
    success: false,
    resetSuccess: false,
    selectedFiles: null,
    selectAll: false
}

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: initialState,
    reducers: {
        resetState: (state) => {
            state.loading = false;
            state.files = [];
            state.error = '';
        },
        resetPreviewFile: (state) => {
            state.file = null
            state.preview = null
        },
        resetIsDeleted: (state) => {
            state.isDeleted = false
        },
        resetSuggestions: (state) => {
            state.suggestions = undefined
        },
        updateFilter: (state, action) => {
            state.filter = { ...state.filter, ...action.payload }
            state.resetSuccess = false;
        },
        resetFilter: (state) => {
            state.filter = {
                ...state.filter, ...{
                    fileTypes: null,
                    orientations: null,
                    dateRange: null,
                    orderType: null
                } as SearchRequest
            }
            state.resetSuccess = true;
        },
        resetSuccess: (state, { payload }) => {
            state.success = payload
        },
        updateFileSelect: (state, { payload }) => {
            if (state.files) {
                state.files[payload.index].isSelected = payload.isSelected
                state.selectedFiles = state.files.filter(item => item.isSelected === true)
                state.selectAll = state.selectedFiles.length === state.files.length
            }
        },
        setSelectAll: (state, { payload }) => {
            state.selectAll = payload
            state.files?.forEach(item => {
                item.isSelected = payload
            })
            state.selectedFiles = state.files?.filter(item => item.isSelected === true)
        },
        setEditInvertSelection: (state) => {
            if (state.selectedFiles) {
                state.selectedFiles.forEach(item => {
                    item.isSelected = !item.isSelected
                })
                state.selectedFiles = state.selectedFiles.filter(item => item.isSelected === true)
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadFiles.pending, (state) => {
                state.loading = true
                state.error = '';
            })
            .addCase(loadFiles.fulfilled, (state, { payload }) => {
                state.loading = false
                payload.forEach((item: FilesModel, index: number) => {
                    item.isSelected = false
                    item.index = index
                })
                state.files = payload
            })
            .addCase(loadFiles.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to load'
            })

            .addCase(loadFileDetails.pending, (state) => {
                state.loading = true
                state.error = '';
            })
            .addCase(loadFileDetails.fulfilled, (state, { payload }) => {
                state.loading = false
                state.file = payload
            })
            .addCase(loadFileDetails.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to load'
            })


            .addCase(deleteFile.pending, (state) => {
                state.loading = true
                state.error = '';
            })
            .addCase(deleteFile.fulfilled, (state, { payload }) => {
                state.loading = false
                state.isDeleted = payload
            })
            .addCase(deleteFile.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to delete'
            })


            .addCase(searchFiles.pending, (state) => {
                state.loading = true
                state.error = '';
            })
            .addCase(searchFiles.fulfilled, (state, { payload }) => {
                state.loading = false
                state.suggestions = payload
            })
            .addCase(searchFiles.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to load'
            })

            .addCase(createCompany.pending, (state) => {
                state.loading = true
                state.error = null
                state.success = false
            })
            .addCase(createCompany.fulfilled, (state, { payload }) => {
                state.loading = false
                state.success = payload.companyId ? true : false
            })
            .addCase(createCompany.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to login'
            })
    },
});

export const { resetState, resetPreviewFile, updateFileSelect, setSelectAll,
    setEditInvertSelection,
    resetSuccess, resetIsDeleted, resetSuggestions, updateFilter, resetFilter } = dashboardSlice.actions
export default dashboardSlice.reducer;