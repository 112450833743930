import { Button } from "../../core/components/ui/button"
import { NavLink } from 'react-router-dom';
import { Label } from "../../core/components/ui/label"
import { Input } from '../../core/components/ui/input';
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from "../../core/components/ui/card";
import { useForm } from "react-hook-form";
import { AppDispatch, RootState } from "../../redux-store/stores/store";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../../shared/services/api";
import { useNavigate } from 'react-router-dom';
import { useEffect } from "react";

export default function ForgetPasswordForm() {
  const dispatch: AppDispatch = useDispatch()
  const { error, success } = useSelector((state: RootState) => state.auth)
  const { register, handleSubmit, reset } = useForm()
  const navigate = useNavigate();


  useEffect(() => {
    if (success) {
      navigate('/forgetpasswordInfo');
    }
  }, [success, navigate])

  useEffect(() => {
    if (error) {
      reset();
    }
  }, [error, reset]);

  const submitForm = (payload: any) => {
    dispatch(forgotPassword(payload));
  }
  return (
    <div className="flex flex-col min-h-screen w-full items-center justify-center">
      <div className="flex h-full items-center justify-center">
        <Card className="mx-auto max-w-sm">
          <CardHeader>
            <CardTitle className="text-2xl">Forgot you password</CardTitle>
            <CardDescription>
              Enter your email below to receive rest link
            </CardDescription>
          </CardHeader>
          <CardContent>
            <form onSubmit={handleSubmit(submitForm)}>
              <div className="grid gap-4">
                <div className="grid gap-2">
                  <Label htmlFor="email">Email</Label>
                  <Input
                    id="email"
                    type="email"
                    placeholder="m@example.com"
                    required
                    {...register('email')} />
                </div>

                <Button type="submit" className="w-full">
                  Reset
                </Button>
              </div>
            </form>
            <div className="mt-4 text-center text-sm">
              <NavLink to="/login" className="underline">
                Go back
              </NavLink>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}