import { ChevronLeft, ChevronRight } from "lucide-react"
import { useState } from "react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { deleteSelecteFiles, pushSelectedFile, resetSelectedFiles, resetState, selectAllUpdate, selectNextPrevFile,  updateTitle } from "../../../redux-store/reducers/upload-reducers"
import { resetSuccess, setEditInvertSelection } from "../../../redux-store/reducers/dashboard-reducer"
import { AppDispatch, RootState } from "../../../redux-store/stores/store"
import { FilesModel } from "../../models/files-model"
import { Button } from "../../../core/components/ui/button"
import { cn } from "../../../core/lib/utils"
import { Checkbox } from "../../../core/components/ui/checkbox"
import { ScrollArea } from "../../../core/components/ui/scroll-area"
import { ImageThumbnail } from "../image-thumbnail/image-thumbnail"
import { Input } from "../../../core/components/ui/input"

export const FileEditor = () => {

    const { success, selectedFiles, files } = useSelector((state: RootState) => state.dashboard);
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()

    const [selectAll, setSelectAll] = useState(false);

    // const [originalTitles, setOriginalTitles] = useState<string[]>([]);
    const [title, setTitle] = useState('');

    const updateFileSelection = (item: FilesModel) => {
        dispatch(pushSelectedFile(item));
    };

    const invertSelection = () => {
        dispatch(setEditInvertSelection())
    }

    const toggleSelectAll = () => {
        setSelectAll(!selectAll)
    };


    useEffect(() => {

    }, [])

    const handleDeleteFile = (item: FilesModel) => {
        dispatch(deleteSelecteFiles(item))
    }


    const handleButtonDone = () => {
        if (selectedFiles && selectedFiles.length > 0) {
            // dispatch(completeUpload(selectedFiles))
        }

        navigate('/home')
    }

    useEffect(() => {
        if (success) {
            dispatch(selectAllUpdate(false))
            dispatch(resetSelectedFiles())
            dispatch(resetState())
            dispatch(resetSuccess(false))
            navigate('/home')
        } // eslint-disable-next-line
    }, [success])



    const switchNextPrev = (item: FilesModel) => {
        dispatch(selectNextPrevFile(item));
    }

    const updateTitleHandler = (e: any) => {
        const newTitle = e.target.value;
        setTitle(newTitle);
    }

    // const updateKeyUpTitleHandler = (e: any) => {
    //     const newTitle = e.target.value;
    //     // Update all selected files with the new title
    //     selectedFiles?.forEach(file => {
    //         dispatch(updateTitle({ fileId: file.id, title: newTitle }));
    //     });
    // }


    const updateTitleSingleHandler = (item: any, text: string) => {
        const newTitle = text;
        setTitle(newTitle);
        // Update all selected files with the new title
        dispatch(updateTitle({ fileId: item.id, title: newTitle }));

    }


    useEffect(() => {
        if (selectedFiles && selectedFiles?.length > 0) {
            // Only set the title for a single file selection, or keep it as it is for multiple files
            if (selectedFiles?.length === 1) {
                setTitle(selectedFiles[0].title || '');
            }
        }

        const isSelectedCount = selectedFiles?.filter(item => item.isSelected === true).length
        if (isSelectedCount === selectedFiles?.length) {
            setSelectAll(true)
        } else {
            setSelectAll(false)
        }
        // eslint-disable-next-line
    }, [selectedFiles]);


    return (
        <div className="flex flex-col w-full min-h-screen">
            <div className="flex flex-row min-h-[74px] z-10 shadow-lg justify-between px-8 items-center relative">
                <div className="flex flex-row w-full h-full items-center">
                    <div className="flex flex-row w-full items-center space-x-3 font-semi-bold ">
                        <p className={cn("")}>Editing {selectedFiles?.length} {selectedFiles && selectedFiles?.length > 0 ? 'assets' : ' asset'} </p>
                    </div>
                    <Button size={'lg'} className="rounded-3xl px-8 py-3" onClick={handleButtonDone}>Done</Button>
                </div>
            </div>
            <div className="flex flex-row w-full h-full min-h-screen">
                <div className="flex flex-col h-full w-full  min-h-screen  bg-[#ececef] bg-muted/30">
                    <div className="flex flex-row w-full min-h-[72px] border-b border-muted-foreground justify-between items-center px-8 py-3">
                        <div className="flex flex-row space-x-3">
                            <div className="flex flex-row items-center space-x-3">
                                <Checkbox id="select all" checked={selectAll} onCheckedChange={toggleSelectAll} className="size-6" />
                                <label
                                    htmlFor="select all"
                                    className="text-sm font-semibold leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                >
                                    {selectedFiles ? selectedFiles.length : 0} of {selectedFiles?.length} selected
                                </label>
                            </div>
                            {
                                (selectedFiles && files && selectedFiles?.length < files?.length && selectedFiles.length > 0) && <Button variant={'ghost'} onClick={invertSelection}>Invert selection</Button>
                            }

                        </div>
                    </div>
                    <div className="flex flex-row w-full max-h-screen h-full pt-6" >
                        <ScrollArea className="flex w-full h-full">
                            {
                                selectedFiles && selectedFiles?.length > 0 &&
                                <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 px-9 py-6">
                                    {selectedFiles.map((item: FilesModel, index: number) =>
                                        <ImageThumbnail key={index} index={index} item={item}
                                            selectionChanged={(item) => updateFileSelection(item as FilesModel)} showDelete={true}
                                            deleteFile={(item: FilesModel): void => {
                                                handleDeleteFile(item as FilesModel)
                                            }}
                                            updateTitle={(item, text) => updateTitleSingleHandler(item, text)}
                                        />
                                    )}

                                </div>
                            }
                        </ScrollArea>
                    </div>
                </div>
                <div className="flec flex-col w-[43rem] min-h-svh bg-card rounded-lg shadow-lg px-9 pt-12 space-x-3">
                    {selectedFiles && selectedFiles.length === 1 && files && files.length !== selectedFiles.length && (
                        <div className="flex flex-row w-full h-fit justify-between">
                            <Button variant={'ghost'} onClick={() => { switchNextPrev(files[selectedFiles[0].index - 1]) }} disabled={selectedFiles[0].index === 0}>
                                <ChevronLeft className="size-4" /> Prev
                            </Button>
                            <Button variant={'ghost'} onClick={() => { switchNextPrev(files[selectedFiles[0].index + 1]) }} disabled={selectedFiles[0].index === files.length - 1}>
                                <ChevronRight className="size-4" />  Next
                            </Button>
                        </div>
                    )}
                    {
                        selectedFiles && selectedFiles.length > 0 ? (

                            < div className="flex w-full flex-col items-start justify-start mb-6">
                                <p className="text-sm font-semibold mb-3">Title</p>
                                <Input
                                    type="text"
                                    className="h-10 w-full"
                                    value={title}
                                    placeholder={selectedFiles.length > 1 ? `Replace ${selectedFiles.length} titles` : ''}
                                    onChange={updateTitleHandler}
                                />
                            </div>
                        ) :
                            <div className="flex flex-row items-center justify-center h-full min-h-screen">
                                <p className="text-center text-xl font-bold w-60">
                                    Select some assets to start editing
                                </p>
                            </div>
                    }
                </div>
            </div>
        </div >
    )
}