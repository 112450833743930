import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '.';


export const resetPassword = createAsyncThunk<void, { newcredentials: any, userId: string | undefined }>(
  'api/resetPassword',
  async ({ newcredentials, userId }, { rejectWithValue }) => {
    const api = axiosInstance
    try {
      const { data } = await api.put(
        '/users/user/change-password/' + userId,
        newcredentials,
      )

      return data
    } catch (error: any) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)


export const getConnectedDrive = createAsyncThunk(
  'api/Plugin/connected',
  async (_, { rejectWithValue }) => {
    const api = axiosInstance
    try {
      const { data } = await api.get('/Plugin/get-integrations');
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
)

export const deleteIntegration = createAsyncThunk(
  'api/Plugin/delete-integration',
  async (request: any, { rejectWithValue }) => {
    const api = axiosInstance
    try {
      const { data } = await api.delete(
        `/Plugin/delete-integration/${request}`,
      )
      return data
    } catch (error: any) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getDrive = createAsyncThunk(
  'api/Plugin/getDrive',
  async (_, { rejectWithValue }) => {
    const api = axiosInstance
    try {
      const { data } = await api.get('/Plugin/get-drives');
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
)


export const addIntegration = createAsyncThunk(
  'api/Plugin/add-integration',
  async (request: any, { rejectWithValue }) => {
    const api = axiosInstance
    try {
      const { data } = await api.post('/Plugin/add-integration', request);
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
)

export const getConnectOathCode = createAsyncThunk(
  'api/Plugin/oauth-url',
  async (pluginId: any, { rejectWithValue }) => {
    const api = axiosInstance
    try {
      const { data } = await api.get(`/Plugin/oauth-url/${pluginId}`);
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
)



export const getFoldersFromDrives = createAsyncThunk(
  'api/plugin/integrationId/folder-content',
  async (pluginId: any, { rejectWithValue }) => {
    const api = axiosInstance
    try {
      const { data } = await api.get(`/Plugin/${pluginId}/folder-content`);
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
)

export const getFoldersFromFolders = createAsyncThunk(
  'api/plugin/integrationId/folder-content/folders',
  async (request: any, { rejectWithValue }) => {
    const api = axiosInstance
    try {
      const { data } = await api.get(`/Plugin/${request.pluginId}/folder-content?folderId=${request.folderId}`);
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
)

export const getStartFolderSync = createAsyncThunk(
  'api/plugin/start-sync',
  async (request: any, { rejectWithValue }) => {
    const api = axiosInstance
    try {
      const { data } = await api.post(`/Plugin/start-sync`, request);
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
)

export const getAllSyncStatus = createAsyncThunk(
  'api/plugin/sync-info',
  async (_processId: any, { rejectWithValue }) => {
    const api = axiosInstance
    try {
      const { data } = await api.get(`/Plugin/sync-info`);
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
)

export const getFolderSyncStatus = createAsyncThunk(
  'api/plugin/sync-status',
  async (processId: any, { rejectWithValue }) => {
    const api = axiosInstance
    try {
      const { data } = await api.get(`/Plugin/sync-info/${processId}`);
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
)



export const updateIntergrationName = createAsyncThunk(
  'api/plugin/update-intergration-name',
  async (request: any, { rejectWithValue }) => {
    const api = axiosInstance
    try {
      const { data } = await api.put(`/Plugin/${request.processId}`,
        { name: request.name }
      );
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
)


export const getStopFolderSync = createAsyncThunk(
  'api/plugin/stop-sync',
  async (processId: any, { rejectWithValue }) => {
    const api = axiosInstance
    try {
      await api.get(`/Plugin/stop-sync?processId=${processId}`);
      return processId;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
)