import { db } from './index'
import { collection, query, orderBy, where, doc, onSnapshot, setDoc, updateDoc, OrderByDirection } from "firebase/firestore";


export interface NestedCollection {
    collection: string;
    doc: string;
}

export interface Where {
    id: string;
    operator: Operators;
    value: string | number | boolean | any[];
}

export enum Operators {
    '<' = '<',
    '<=' = '<=',
    '==' = '==',
    '>' = '>',
    '>=' = '>=',
    '!=' = '!=',
    'array-contains' = 'array-contains',
    'array-contains-any' = 'array-contains-any',
    'in' = 'in',
    'not-in' = 'not-in'
}

export interface OrderBy {
    value: string;
    order?: OrderByDirection;
}


export const FirebaseService = {

    // Listen to data changes in a collection
    listenToCollection: (collectionPath: string, whereConditions: Where[] = [], orderByFields: OrderBy[] = [], callback: (arg0: any) => void) => {
        const pathSegments = collectionPath.split("/");
        let q = query(collection(db, 'env', ...pathSegments));
        whereConditions.forEach((condition) => {
            q = query(q, where(condition.id, condition.operator, condition.value));
        });

        orderByFields.forEach((field) => {
            q = query(q, orderBy(field.value, field.order));
        });

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const documents = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            callback(documents); // Invoke the callback with the updated data
        });

        return unsubscribe; // Return the unsubscribe function for cleanup
    },

    // Listen to data changes in a specific document
    listenToDocument: (documentPath: string, callback: (arg0: { id: string; } | null) => void) => {
        const docRef = doc(db, 'env', ...documentPath.split("/"));

        const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
            if (docSnapshot.exists()) {
                callback({ id: docSnapshot.id, ...docSnapshot.data() });
            } else {
                console.log("Document does not exist!");
                callback(null);
            }
        });

        return unsubscribe;
    },

    writeData: async (documentPath: string, data: any) => {
        const docRef = doc(db, 'env', ...documentPath.split("/"));
        await setDoc(docRef, data, { merge: true });
    },

    updateData: async (documentPath: string, data: any) => {
        const docRef = doc(db, 'env', ...documentPath.split("/"));
        await updateDoc(docRef, data);
    },

}