import React from 'react';
import { DateDisplayProps } from '../../../../shared/models/date-display-props';
import { formatRelativeDate } from '../../../lib/date-util';


const DateDisplay: React.FC<DateDisplayProps> = ({ date }) => {
    return (
        <div>
            {date?formatRelativeDate(date): ''}
        </div>
    );
};

export default DateDisplay;