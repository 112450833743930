import { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "../../../core/components/ui/dialog";
import { Button } from "../../../core/components/ui/button";
import { Label } from "../../../core/components/ui/label";
import { RadioGroup, RadioGroupItem } from "../../../core/components/ui/radio-group";
import { Input } from "../../../core/components/ui/input";
import { AppDispatch } from "../../../redux-store/stores/store";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { teamUserAdd, updateUserdata } from "../../../shared/services/userManage";
import { Users } from "../../../shared/models/userManage-model";
import { Copy } from "lucide-react";
import { resetSuccess } from '../../../redux-store/reducers/dashboard-reducer';
import { toast } from "react-toastify";
import { RefreshAuthToken } from "../../../shared/services";

interface FileDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    editMode?: boolean;
    user?: Users | null;
    userValues?: any | null;
    profileEdit?: boolean;
}

const TeamMember: React.FC<FileDialogProps> = ({ open, setOpen, editMode, user, userValues, profileEdit }) => {
    const dispatch: AppDispatch = useDispatch();
    const [Password, setTempPassword] = useState<string>("");
    const [passwordGenerated, setPasswordGenerated] = useState<boolean>(false);



    const defaultValues = {
        Fullname: '',
        Email: '',
        code: '',
        phoneNumber: '',
        JobTitle: '',
        Role: 0,
        terms: false,
    };

    const { register, handleSubmit, watch, formState: { errors, isDirty, isValid }, reset, getValues, control } = useForm({
        mode: 'onChange',
        defaultValues: defaultValues,
    });

    useEffect(() => {
        if (editMode && user) {
            const mobileNo = user.userInfo.mobileNo ? user.userInfo.mobileNo.toString() : '';
            const countryCode = mobileNo.substring(0, 3);
            const phoneNumber = mobileNo.substring(3)
            reset({
                Fullname: user.fullName || '',
                Email: user.email || '',
                code: countryCode || '',
                phoneNumber: phoneNumber || '',
                JobTitle: user.userInfo.jobTitle || '',
                Role: user.role || 0,
                terms: false,
            });
        } else if (profileEdit) {
            const mobileNo = userValues.userMobileNumber ? userValues.userMobileNumber.toString() : '';
            const countryCode = mobileNo.substring(0, 3);
            const phoneNumber = mobileNo.substring(3);

            reset({
                Fullname: userValues.userName || '',
                Email: userValues.userEmail || '',
                code: countryCode || '',
                phoneNumber: phoneNumber || '',
                JobTitle: userValues.userJobTitle || '',
            });
        } else {
            reset(defaultValues);
        }

    }, [editMode, profileEdit, user, reset]);



    useEffect(() => {
        const subscription = watch((value) => {

            if (value.Email && !passwordGenerated && !editMode && !profileEdit) {
                const generatedPassword = generateTempPassword();
                setTempPassword(generatedPassword);
                setPasswordGenerated(true);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, passwordGenerated, editMode, profileEdit]);



    const handleDialogChange = (isOpen: boolean) => {
        setOpen(isOpen);
        if (!isOpen) {
            // Reset form when dialog is closed
            // reset(defaultValues);
            // setPasswordGenerated(false);
        }
    };

    const refresh = async () => {
        const refreshed = await RefreshAuthToken();
        if (refreshed) {
            dispatch(resetSuccess(false))
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        }
    }



    const generateTempPassword = (length = 8) => {
        const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let password = "";
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            password += charset[randomIndex];
        }
        return password;
    };



    const submitForm = (payload: any) => {
        const code = getValues('code');
        const phoneNumber = getValues('phoneNumber');
        const fullPhoneNumber = `${code}${phoneNumber}`;

        const formData = {
            ...payload,
            phoneNumber: fullPhoneNumber,
            Role: parseInt(payload.Role, 10),
            ...(editMode || profileEdit ? {} : { Password }),
        };

        if (!editMode && !profileEdit) {
            dispatch(teamUserAdd(formData)); // Add new user if not editing
        }

        // dispatch(teamUserAdd(formData));
        reset(defaultValues);
        setOpen(false)
        setPasswordGenerated(false);
    };

    const updateUser = () => {
        const payload = getValues();
        const code = getValues('code');
        const phoneNumber = getValues('phoneNumber');
        const fullPhoneNumber = `${code}${phoneNumber}`;

        const formData = {
            ...payload,
            phoneNumber: fullPhoneNumber,
            Role: parseInt((payload.Role ?? 0).toString(), 10),
        };
        const newPayload = {
            formData: formData,
            userId: profileEdit && userValues ? userValues.userId : user?.userId
        };
        if (profileEdit) {
            dispatch(updateUserdata(newPayload))
                .then(() => {
                    refresh();
                    toast.success("user updated successfully");
                })
                .catch((error) => {
                    toast.error('Error updating user data:', error);
                });

        } else {
            dispatch(updateUserdata(newPayload));
        }
        setTimeout(() => {
            setOpen(false);
        }, 1500);
    };

    // Copy password to clipboard
    const copyPasswordToClipboard = () => {
        navigator.clipboard.writeText(Password).then(() => {
            toast.success("Password copied to clipboard!")
        }).catch(err => {
            toast.error("Failed to copy token.");
        });
    };

    const isButtonDisabled = !isDirty || !isValid;

    return (
        <Dialog open={open} onOpenChange={handleDialogChange}>
            <DialogContent className="sm:max-w-[600px] bg-white">
                <DialogHeader className="flex flex-col items-center justify-center">
                    <DialogTitle className="font-bold text-2xl">{editMode || profileEdit ? 'Edit User' : 'Add New User'}</DialogTitle>
                    <DialogDescription></DialogDescription>
                </DialogHeader>
                <form onSubmit={handleSubmit(submitForm)} className="space-y-4">
                    <div>
                        <Label className="block text-sm font-medium">Full Name</Label>
                        <Input
                            id="FullName"
                            type="text"
                            className="mt-1 block w-full p-2 border border-gray-300 rounded"
                            {...register('Fullname', { required: 'Your full name is required' })}
                        />
                        {errors.Fullname && <span className="text-xs text-red-600">{errors.Fullname.message}</span>}
                    </div>
                    <div className="flex space-x-2">
                        <div className="w-1/4">
                            <Label className="block text-sm font-medium">Country Code</Label>
                            <Input
                                id="code"
                                type="text"
                                placeholder="+1"
                                className="w-full p-2 border border-gray-300 rounded"
                                {...register('code')}
                            />
                        </div>
                        <div className="w-3/4">
                            <Label className="block text-sm font-medium">Phone Number</Label>
                            <Input
                                id="phoneNumber"
                                type="text"
                                placeholder="Phone Number"
                                className="w-full p-2 border border-gray-300 rounded"
                                {...register('phoneNumber')}
                            />
                        </div>
                    </div>

                    <div>
                        <Label className="block text-sm font-medium">Job Title</Label>
                        <Input
                            id="JobTitle"
                            type="text"
                            className="mt-1 block w-full p-2 border border-gray-300 rounded"
                            {...register('JobTitle')}
                        />
                    </div>

                    <div>
                        <Label className="block text-sm font-medium">Email Address</Label>
                        <Input
                            id="Email"
                            type="email"
                            autoComplete='false'
                            className="mt-1 block w-full p-2 border border-gray-300 rounded"
                            {...register('Email', { required: 'Your email address is required' })}
                        />
                        {errors.Email && <span className="text-xs text-red-600">{errors.Email.message}</span>}
                    </div>

                    {!editMode && !profileEdit && (
                        <div>
                            <Label className="block text-sm font-medium">Temporary Password</Label>
                            <div className="mt-1 p-2 border border-gray-300 rounded bg-gray-100 flex justify-between items-center">
                                {Password ? "********" : 'Password will be generated when you enter an email address'}
                                {Password && (
                                    <button
                                        type="button"
                                        className="ml-2 text-blue-600"
                                        onClick={copyPasswordToClipboard}
                                    >
                                        <Copy />
                                    </button>
                                )}
                            </div>
                        </div>
                    )}

                    {!profileEdit && (
                        <div>
                            <Label className="block text-sm font-medium mb-2">Assign User Role</Label>
                            <Controller
                                name="Role"
                                control={control}
                                rules={{ required: 'You must assign a role' }}
                                render={({ field }) => (
                                    <RadioGroup className="flex space-x-4" value={field.value.toString()} onValueChange={field.onChange}>
                                        <div className="flex items-center space-x-2">
                                            <RadioGroupItem value="0" id="r1" />
                                            <Label htmlFor="r1">Admin</Label>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            <RadioGroupItem value="1" id="r2" />
                                            <Label htmlFor="r2">User</Label>
                                        </div>
                                    </RadioGroup>
                                )}
                            />
                            {errors.Role && <span className="text-xs text-red-600">{errors.Role.message}</span>}
                        </div>
                    )}

                    {!editMode && !profileEdit && (
                        <div className="flex mt-6 justify-start items-start space-x-2">
                            <input
                                type="checkbox"
                                id="terms"
                                {...register('terms')}
                            />
                            <Label
                                htmlFor="terms"
                                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                            >
                                Send password and username to the mail
                            </Label>
                        </div>
                    )}

                    <div className="flex flex-col justify-end items-end">
                        {!(editMode || profileEdit) ? (
                            <Button type="submit" disabled={isButtonDisabled}>
                                Add User
                            </Button>
                        ) : (
                            <Button type="button" onClick={updateUser} disabled={isButtonDisabled}>
                                Update User
                            </Button>
                        )}
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default TeamMember;
