import { MonitorSmartphone, UploadCloud } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux-store/stores/store";
import { Button } from "../../ui/button";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "../../ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/tabs";
import { resetState, updatePendingFiles } from "../../../../redux-store/reducers/upload-reducers";
import { useNavigate } from "react-router-dom";

interface FileDialogProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    files?: any[] | null;
    setFiles?: React.Dispatch<React.SetStateAction<any[] | null>>;
}

const DropedFilesViewer: React.FC<FileDialogProps> = ({ open, setOpen, files: externalFiles, setFiles: externalSetFiles }) => {
    const [internalFiles, setInternalFiles] = useState<any[] | null>(null);
    const dispatch = useDispatch<AppDispatch>();

    const navigate = useNavigate();

    useEffect(() => {
        if (externalFiles) {
            setInternalFiles(externalFiles);
        }
    }, [externalFiles]);

    const setFiles = externalSetFiles || setInternalFiles;
    const files = externalFiles || internalFiles;

    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.dataTransfer.files) {
            const selectedFiles = [...event.dataTransfer.files].map(file => {
                return Object.assign(file, {
                    preview: URL.createObjectURL(file),
                    contentType: file.type
                });
            });
            setFiles((prevFiles) => (prevFiles ? [...prevFiles, ...selectedFiles] : selectedFiles));
        }
    };

    useEffect(() => {
        dispatch(resetState())
        if (files) {
            dispatch(updatePendingFiles(files))
            navigate('/browse/upload')
        }
    }, [files, dispatch, navigate])

    const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = Array.from(event.target.files || []).map(file => {
            return Object.assign(file, {
                preview: URL.createObjectURL(file),
                contentType: file.type
            });
        });
        setFiles((prevFiles) => (prevFiles ? [...prevFiles, ...selectedFiles] : selectedFiles));
    };

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    useEffect(() => {
        if (!open) {
            setFiles(null);
        }
    }, [open, setFiles]);


    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogContent className="sm:max-w-[1000px]">
                <DialogHeader className="flex flex-row items-center justify-center">
                    <DialogTitle className="font-bold text-2xl">Upload your files</DialogTitle>
                    <DialogDescription></DialogDescription>
                </DialogHeader>
                <Tabs defaultValue="local" className="w-full">
                    <TabsList className="grid w-full grid-cols-3 p-2 min-h-24 bg-transparent">
                        <TabsTrigger value="local" className="min-h-20 bg-card shadow">
                            <div className="flex flex-col items-center justify-center w-full space-y-2">
                                <MonitorSmartphone className="size-6" />
                                <p>Your Computer</p>
                            </div>
                        </TabsTrigger>
                    </TabsList>

                    <TabsContent value="local">
                        <div className="flex flex-row w-full">
                            <div
                                className="flex flex-col w-full h-96 p-4 bg-slate-100 rounded-2xl border-dotted border-slate-800 border-2 items-center justify-center text-center space-y-2"
                                onDragOver={handleDragOver}
                                onDrop={handleDrop}
                            >
                                <div className="text-muted-foreground">Drag files here to start uploading</div>
                                <div className="flex justify-center text-xs uppercase">
                                    <span className="px-2 text-muted-foreground">or</span>
                                </div>
                                <input
                                    type="file"
                                    multiple
                                    onChange={handleFileInputChange}
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    accept="image/png, image/jpeg, image/avif, image/webp, application/pdf, text/plain"
                                />
                                <Button
                                    className="flex flex-row rounded-3xl space-x-3 items-center justify-center px-4"
                                    onClick={handleButtonClick}
                                >
                                    <UploadCloud className="size-4" />
                                    <p>Browse Files</p>
                                </Button>
                            </div>
                        </div>
                    </TabsContent>
                </Tabs>
            </DialogContent>
        </Dialog>
    );
};

export default DropedFilesViewer;


// {files && (
//     <ScrollArea className="h-full w-1/2">
//         <div className="flex flex-col w-full p-4 h-full space-y-3">
//             {files.map((item, index) => (
//                 <div key={index} className="flex flex-row w-full items-center space-x-3">
//                     <FileViewer className="size-20" height={100} isThumbnail={true} file={item} />
//                     <div className="flex flex-col w-full">
//                         <p>{item.name}</p>
//                         {progress[index] && <Progress value={progress[index]} />}
//                     </div>
//                     <Button
//                         onClick={() => handleRemoveFile(index)}
//                         variant={'outline'}
//                         size={'icon'}
//                         className="rounded-full text-red-600 hover:text-red-600"
//                     >
//                         <Trash2 className="size-4" />
//                     </Button>
//                 </div>
//             ))}
//         </div>
//     </ScrollArea>
// )}